export enum UserType {
  Guide,
  User,
  Browse,
}

export interface User {
  type: UserType;
  color: string[];
  token: number;
}
