export class Color {
  private readonly color: [string, string];

  constructor() {
    this.color = this.createColor();
  }

  getColor(): [string, string] {
    return this.color;
  }

  private createColor(): [string, string] {
    // const color = ((Math.random() * 0xffffff) | 0).toString(16);
    // return `#${('000000' + color).slice(-6)}`;
    const r = Math.round(Math.random() * 180);
    const g = Math.round(Math.random() * 180);
    const b = Math.round(Math.random() * 180);

    return [`rgba(${r},${g},${b},1)`, `rgba(${r},${g},${b},0.8)`];
  }
}
