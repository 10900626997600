import * as L from 'leaflet';
import Stroly from 'stroly-js';

import { CommentMessage } from '../domains/ws/message.model';
import { Character } from '../domains/user/character';
import { LatLng, ActiveUser } from '../domains/map/map.model';

import { CentralMarkerFactory } from '../domains/marker/central-marker-factory';
import { CentralMarkers } from '../domains/marker/central-markers';

export class CentralMarkerService {
  private readonly centralMarkerFactory: CentralMarkerFactory;
  private readonly centralMarkers: CentralMarkers = new CentralMarkers();
  private readonly centralMarkersGroup = new L.LayerGroup();
  private timers = new Map<number, NodeJS.Timeout>();

  constructor(private stroly: Stroly, private myToken: number) {
    this.centralMarkersGroup.addTo(stroly.map);
    this.centralMarkerFactory = new CentralMarkerFactory();
  }

  create(token: number, colors: string[], character: Character) {
    const centralMarker = this.centralMarkers.get(token);

    if (centralMarker) {
      return;
    }
    this.putOnMap(token, this.stroly.map.getCenter(), character, colors);
    this.fireCentralMove(token);
  }

  move(activeUser: ActiveUser) {
    const centralMarker = this.centralMarkers.get(activeUser.token);

    if (centralMarker) {
      centralMarker.setLatLng([activeUser.lat, activeUser.lng]);
      return;
    }

    this.putOnMap(
      activeUser.token,
      { lat: activeUser.lat, lng: activeUser.lng },
      new Character(activeUser.name, activeUser.characterType),
      activeUser.color,
    );
  }

  panTo(token: number) {
    const centralMarker = this.centralMarkers.get(token);
    if (centralMarker) {
      this.stroly.map.panTo(centralMarker.getLatLng());
    }
  }

  remove(token: number) {
    const centralMarker = this.centralMarkers.get(token);
    if (centralMarker) {
      this.stroly.map.removeLayer(centralMarker);
      this.centralMarkers.delete(token);
      this.centralMarkersGroup.removeLayer(centralMarker);
    }
  }

  setComment(message: CommentMessage) {
    const timer = this.timers.get(message.token);
    if (timer) {
      clearInterval(timer);
    }
    const centralMarker = this.centralMarkers.get(message.token);

    if (centralMarker) {
      const CentralMarker = this.centralMarkerFactory.centralMarkerByName(
        message.characterType,
      );

      centralMarker.setIcon(
        CentralMarker.createIcon(
          new Character(message.name, message.characterType),
          message.color,
          message.comment,
        ),
      );

      this.timers.set(
        message.token,
        setInterval(() => {
          centralMarker.setIcon(
            CentralMarker.createIcon(
              new Character(message.name, message.characterType),
              message.color,
            ),
          );

          const timer = this.timers.get(message.token);
          if (timer) {
            clearInterval(timer);
          }
        }, 8000),
      );
    }
  }

  fireCentralMove(token: number) {
    const centralMarker = this.centralMarkers.get(token);
    if (centralMarker) {
      const center = this.stroly.map.getCenter();
      centralMarker.setLatLng(center);
      this.stroly.map.fire('centralmove', center);
    }
  }

  private putOnMap(
    token: number,
    latlng: LatLng,
    character: Character,
    colors: string[],
  ) {
    const zoomSize = this.stroly.map.getZoom();
    const CentralMarker = this.centralMarkerFactory.centralMarkerByName(
      character.getCharacterType(),
    );
    const newCentralMarker = new CentralMarker(
      this.stroly.L,
      latlng,
      character,
      colors,
      token === this.myToken,
      zoomSize,
    ).marker.addEventListener('contextmenu', () => {
      this.stroly.map.fire('deportation', { token });
    });

    this.centralMarkersGroup.addLayer(newCentralMarker);
    this.centralMarkers.set(token, newCentralMarker);
  }
}
