import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { CharacterType } from '../../domains/user/character';
import * as Constants from '../../core/constants';

type Props = {
  color: string[];
  isMobile: boolean;
  handleCharacterSelected: (character: CharacterType) => void;
};

const ConnectionImage = React.memo((props: Props) => {
  const iconSize = props.isMobile ? '24px' : '32px';

  const style: { [key: string]: React.CSSProperties } = {
    div: {
      marginTop: '8px',
      display: 'flex',
    },
    text: {
      color: '#666',
      fontSize: '12px',
      fontWeight: 'bold',
      width: '88px',
    },
    required: {
      color: '#f60',
    },
    span: {
      display: 'inline-block',
      borderRadius: '50%',
    },
    vtour: {
      backgroundSize: 'cover',
      backgroundColor: '#e7e7e7',
    },
    selected: {
      margin: '2px',
      width: iconSize,
      height: iconSize,
      border: '4px solid #eab540',
      cursor: 'pointer',
      borderRadius: '50%',
    },
    nonSelected: {
      margin: '6px',
      width: iconSize,
      height: iconSize,
      cursor: 'pointer',
    },
    randomSpan: {
      display: 'inline-block',
      margin: '6px',
      backgroundColor: '#666',
      borderRadius: '50%',
      width: iconSize,
      height: iconSize,
      position: 'relative',
      cursor: 'pointer',
    },
    random: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    name: {
      fontSize: '14px',
      fontWeight: 'bold',
      padding: '2px 16px',
      borderRadius: '4px',
      color: '#fff',
      backgroundColor: props.color[0],
    },
  };

  const [selected, setSelected] = React.useState<CharacterType>(
    CharacterType.StudentMan,
  );

  const handleClick = useCallback(
    (character?: CharacterType) => {
      let selected = character;

      if (!character) {
        const characterList = Constants.VTourCharactersData;
        const random = Math.floor(Math.random() * characterList.length);
        selected = characterList[random].type;
      }

      setSelected(selected as CharacterType);
      props.handleCharacterSelected(selected as CharacterType);
    },
    [props],
  );

  const characters = Constants.VTourCharactersData.map((character) => {
    return (
      <div
        key={character.type}
        style={{
          backgroundImage: `url('${character.image}')`,
          ...style.span,
          ...style.vtour,
          ...(selected === character.type ? style.selected : style.nonSelected),
        }}
        onClick={() => handleClick(character.type)}></div>
    );
  });

  return (
    <div style={style.div}>
      <p style={style.text}>
        <span style={style.required}>＊</span>
        <FormattedMessage id="ConnectionImage.Character" />
      </p>
      <div style={style.iconBox}>
        {characters}
        <span style={style.randomSpan} onClick={() => handleClick()}>
          <img
            src="/images/icons/random.svg"
            alt="random"
            width="16"
            style={style.random}
          />
        </span>
      </div>
    </div>
  );
});

export default ConnectionImage;
