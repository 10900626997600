import { useEffect, useState } from 'react';

type Message = {
  [id: string]: string;
};

export function useLang(messages: { ja: Message; en: Message }) {
  const [language, setlanguage] = useState('ja');
  const [message, setMessage] = useState(messages.ja);

  useEffect(() => {
    const lang =
      (window.navigator.languages && window.navigator.languages[0]) ||
      window.navigator.language;

    if (lang !== 'ja' && lang !== 'ja-JP') {
      setlanguage('en');
      setMessage(messages.en);
    }
  }, [messages]);

  return {
    language,
    message,
  };
}
