import React, { useEffect, useCallback, useRef } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { History } from 'history';

import { StateContext, DispatchContext } from '../core/contexts';
import { ConnectionsRepository } from '../repositories/connections.repository';
import { useAvailabilityService } from './hooks/useAvailabilityService';
import { UserType } from '../domains/user/user.model';
import { GuideOptions } from './AppContainer';
import { CharacterType, Character } from '../domains/user/character';
import { updateUser, updateIsLoading } from '../core/reducer';

import Overlay from '../components/connection/Overlay';
import About from '../components/map/About';
import ConnectionModal from '../components/connection/ConnectionModal';
import ConnectionImage from '../components/connection/ConnectionImage';
import ConnectionGuideImage from '../components/connection/ConnectionGuideImage';
import ConnectionParticipants from '../components/connection/ConnectionParticipants';
import ConnectionButton from '../components/connection/ConnectionButton';
import ConnectionNameForm from '../components/connection/ConnectionNameForm';
import ConnectionStartDate from '../components/connection/ConnectionStartDate';
import ConnectionBrowse from '../components/connection/ConnectionBrowse';

type Props = {
  intl: IntlShape;
  history: History;
  guideOptions: GuideOptions;
  name: string;
  character: Character;
  protocol: string;
  isLogedIn: boolean;
  isMobile: boolean;
  isSmallDisplay: boolean;
  initVirtualMap(): void;
  handleCharacterSelected(characterType: CharacterType): void;
  handleNameChange(name: string): void;
};

const ConnectionContainer = React.memo((props: Props) => {
  const state = React.useContext(StateContext);
  const dispatch = React.useContext(DispatchContext);
  const connectionsRepository = useRef(new ConnectionsRepository());

  const [isDisabled, setIsDisabled] = React.useState(true);
  const [isDisplay, setIsDisplay] = React.useState(true);

  const availabilityService = useAvailabilityService(
    props.history,
    dispatch,
    props.protocol,
    props.guideOptions.limit,
  );
  useEffect(() => {
    if (!props.guideOptions?.isGuideMode) {
      return;
    }
    const message = props.intl.formatMessage({
      id: 'VmapContainer.FinishedTour.AlertMessage',
    });
    availabilityService.checkIsAfterTour(message);
    availabilityService.checkStartDateAndParticipants();
  }, []);

  async function handleConnectionButtonClick() {
    gtag('event', 'login', {
      event_category: 'selectedCharacter',
      event_label: props.character.getCharacterType(),
    });

    if (
      !props.guideOptions?.isGuideMode ||
      state.user.type === UserType.Guide
    ) {
      props.initVirtualMap();
      return;
    }

    const isError = await availabilityService.checkStartDateAndParticipants();
    if (isError) {
      return;
    }

    dispatch(updateIsLoading(true));
    connectionsRepository.current
      .post(props.protocol, String(state.user.token))
      .then(() => {
        dispatch(updateIsLoading(false));
        props.initVirtualMap();
      })
      .catch((error: any) => {
        dispatch(updateIsLoading(false));
        props.history.push(
          `/error/${props.protocol}`,
          props.history.location.pathname,
        );
      });
  }

  async function handleBrowseModeClick() {
    gtag('event', 'login', {
      event_category: 'selectedCharacter',
      event_label: 'browse mode',
    });

    if (props.guideOptions?.isGuideMode) {
      await availabilityService.checkInBrowseMode();
    }

    dispatch(
      updateUser({
        type: UserType.Browse,
      }),
    );
  }

  const handleChange = useCallback(
    (name: string) => {
      props.handleNameChange(name);

      if (name.length > 0) {
        setIsDisabled(false);
        return;
      }
      setIsDisabled(true);
    },
    [props],
  );

  React.useEffect(() => {
    if (props.isLogedIn) {
      setIsDisplay(false);
      return;
    }
    setIsDisplay(true);
  }, [props.isLogedIn]);

  return (
    <Overlay isDisplay={isDisplay}>
      <ConnectionModal
        title={state.metaData?.title}
        userType={state.user.type}
        isGuideMode={props.guideOptions?.isGuideMode}
        isFull={availabilityService.isFull}
        limit={props.guideOptions?.limit}
        isBeforeTour={availabilityService.isBeforeTour}
        startDate={availabilityService.startDate}
        isMobile={props.isMobile}
        isSmallDisplay={props.isSmallDisplay}>
        {state.user.type === UserType.Guide ? (
          <>
            <ConnectionGuideImage
              handleCharacterSelected={props.handleCharacterSelected}
            />
            <ConnectionNameForm
              userType={state.user.type}
              name={props.name}
              isSmallDisplay={props.isSmallDisplay}
              handleChange={handleChange}
            />
            <ConnectionStartDate
              guideOptions={props.guideOptions}
              startDate={availabilityService.startDate}
              isMobile={props.isMobile}
              userType={state.user.type}
            />
            <ConnectionParticipants
              participants={availabilityService.participants}
              limit={props.guideOptions?.limit}
              isMobile={props.isMobile}
            />
          </>
        ) : (
          <>
            <ConnectionStartDate
              guideOptions={props.guideOptions}
              startDate={availabilityService.startDate}
              isMobile={props.isMobile}
              userType={state.user.type}
            />
            <ConnectionParticipants
              participants={availabilityService.participants}
              limit={props.guideOptions?.limit}
              isMobile={props.isMobile}
            />
            <ConnectionNameForm
              userType={state.user.type}
              name={props.name}
              isSmallDisplay={props.isSmallDisplay}
              handleChange={handleChange}
            />
            <ConnectionImage
              color={state.user.color}
              handleCharacterSelected={props.handleCharacterSelected}
              isMobile={props.isMobile}
            />
          </>
        )}
        <ConnectionButton
          isDisabled={isDisabled}
          isGuideMode={props.guideOptions.isGuideMode}
          handleClick={handleConnectionButtonClick}
        />
        <ConnectionBrowse
          isGuideMode={props.guideOptions.isGuideMode}
          isFull={availabilityService.isFull}
          handleBrowseModeClick={handleBrowseModeClick}
        />
        <About guideOptions={props.guideOptions} />
      </ConnectionModal>
    </Overlay>
  );
});

export default injectIntl(ConnectionContainer);
