import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { useWS } from './hooks/useWS';
import { CommentMessage } from '../domains/ws/message.model';
import { EmojiName, Emoji } from '../domains/ws/emoji';
import { LatLng } from '../domains/map/map.model';
import { GuideOptions } from './AppContainer';
import { CharacterType } from '../domains/user/character';
import { MapService } from '../domains/map';
import { StateContext, DispatchContext } from '../core/contexts';

import Landmark from '../components/comment/Landmark';
import CommentForm from '../components/comment/CommentForm';
import CommentList from '../components/comment/CommentList';

type Props = {
  name: string;
  characterType: CharacterType;
  guideOptions: GuideOptions;
  unReaded: number;
  isLogedIn: boolean;
  isShareMode: boolean;
  isMobile: boolean;
  protocol: string;
  mapService: MapService;
  setCommentToCentralMarker(message: CommentMessage): void;
  setSelectedLandmarkMarker(): void;
  dispatchUpdateComments(omment: CommentMessage): void;
  handleChatButtonClick(): void;
};

const CommentContainer = (props: Props) => {
  const state = React.useContext(StateContext);
  const dispatch = React.useContext(DispatchContext);
  const [comment, setComment] = useState<string>('');
  function handleChange(comment: string) {
    setComment(comment);
  }

  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  /**
   * WebSocketの接続が開始された時の処理
   */
  function handleWSOpen() {
    setIsDisabled(false);
    handleSendMessage();
  }
  /**
   * WebSocketの接続が切れた時の処理
   */
  function handleWSClose(event: CloseEvent) {
    console.log(
      'Socket for comment is closed. Reconnect will be attempted in 2 second.',
      event.reason,
    );
    setTimeout(() => {
      commentWS.connect();
    }, 2000);
  }
  /**
   * WebSocketサーバーからメッセージを受け取った時の処理
   */
  function handleWSMessage(event: MessageEvent) {
    const commentMessage: CommentMessage = JSON.parse(event.data);
    props.dispatchUpdateComments(commentMessage);
    props.setCommentToCentralMarker(commentMessage);

    if (state.user.token === commentMessage.token) {
      setComment('');
    }
  }
  const commentWS = useWS<CommentMessage>(
    `${process.env.REACT_APP_COMMENT_SHARING_SERVER}/comment`,
    props.protocol,
    {
      handleWSOpen,
      handleWSClose,
      handleWSMessage,
    },
  );

  useEffect(() => {
    if (!commentWS.get() && props.isLogedIn) {
      commentWS.connect();
    }
  }, [props.isLogedIn]);

  function handleSendButtonClick(comment: string | EmojiName) {
    const centerLatLng = props.isShareMode
      ? props.mapService.getLocationMarkerLatLng(state.user.token)
      : props.mapService.getCentralLatLng();
    const date = moment().format('YYYY/MM/DD HH:mm:ss');
    const id = date + Math.random() + state.user.token;

    const commentMessage: CommentMessage = {
      id,
      date,
      token: state.user.token,
      color: state.user.color,
      name: props.name,
      characterType: props.characterType,
      latlng: centerLatLng,
      comment,
      type: Emoji.isEmoji(comment) ? 'emoji' : 'comment',
      room: props.protocol,
    };
    commentWS.send(commentMessage);

    const chatType = Emoji.isEmoji(comment)
      ? new Emoji(comment).getEmojiName()
      : 'comment';

    gtag('event', 'click', {
      event_category: 'chat',
      event_label: chatType,
    });
  }

  /**
   * todo: ランドマークのコメント機能を削除したため、以下のメソッドはもはや必要ない
   * todo: ただ、地図上の任意の場所をつぶやきたいという需要はありそうなので、
   * todo: 使い回せるようにとりあえずコメントアウトして残しておく。
   */
  // function handleLandmarkCommentClick(landmark: {
  //   name: string | undefined;
  //   xy: LatLng;
  // }) {
  //   const date = moment().format('YYYY/MM/DD HH:mm:ss');
  //   const id = date + Math.random() + props.user.token;

  //   const message = props.intl.formatMessage(
  //     { id: 'CommentConatainer.CheckLandmark' },
  //     { landmark: props.landmark.name },
  //   );
  //   const commentMessage: CommentMessage = {
  //     id,
  //     date,
  //     token: props.user.token,
  //     color: props.user.color,
  //     name: props.name,
  //     characterType: props.characterType,
  //     latlng: landmark.xy,
  //     comment: message,
  //     type: 'landmark',
  //     room: props.protocol,
  //   };
  //   commentWS.send(commentMessage);

  //   gtag('event', 'click', {
  //     event_category: 'chat',
  //     event_label: 'landmark',
  //   });
  // }

  function handleSendMessage() {
    if (!comment) {
      return;
    }
    handleSendButtonClick(comment);
  }

  function handleSendEmoji(emoji: EmojiName) {
    handleSendButtonClick(emoji);
  }

  function panToComment(message: CommentMessage) {
    if (message.type !== 'landmark') {
      props.mapService.putCommentMarker(message);
    }

    props.mapService.panTo(message.latlng.lat, message.latlng.lng);

    gtag('event', 'click', {
      event_category: 'panTo',
      event_label: 'CommentList',
    });
  }

  function panToLandmark(latlng: LatLng) {
    props.mapService.panTo(latlng.lat, latlng.lng);

    gtag('event', 'click', {
      event_category: 'panTo',
      event_label: 'Landmark',
    });
  }

  return (
    <>
      <Landmark
        userType={state.user.type}
        isMobile={props.isMobile}
        setSelectedLandmarkMarker={props.setSelectedLandmarkMarker}
        panToLandmark={panToLandmark}
        landmark={state.landmarkContent}
        isLandmarkOpen={state.isLandmarkOpen}
        dispatch={dispatch}
      />
      <CommentForm
        userType={state.user.type}
        guideOptions={props.guideOptions}
        comment={comment}
        unReaded={props.unReaded}
        isDisabled={isDisabled}
        isChatOpen={state.isChatOpen}
        handleChange={handleChange}
        handleSendMessage={handleSendMessage}
        handleSendEmoji={handleSendEmoji}
        handleChatButtonClick={props.handleChatButtonClick}>
        <CommentList
          token={state.user.token}
          guideOptions={props.guideOptions}
          isChatOpen={state.isChatOpen}
          comments={state.comments}
          panToComment={panToComment}
        />
      </CommentForm>
    </>
  );
};

export default CommentContainer;
