export class ConnectionsRepository {
  private readonly getApiPath = (room: string) =>
    `${process.env.REACT_APP_USER_CONNECTIONS_API_URL}/${room}/connections`;
  private readonly postApiPath = () =>
    `${process.env.REACT_APP_USER_CONNECTIONS_API_URL}/c`;
  private readonly deleteApiPath = (room: string) =>
    `${process.env.REACT_APP_USER_CONNECTIONS_API_URL}/d/${room}/connections`;

  get(room: string): Promise<any> {
    return new Promise((res, rej) => {
      fetch(this.getApiPath(room), {
        method: 'GET',
      })
        .then((response) => {
          if (response.ok) {
            return res(response.json());
          }
          return response;
        })
        .then((error) => {
          return rej(error);
        });
    });
  }

  post(room: string, token: string): Promise<any> {
    return new Promise((res, rej) => {
      fetch(this.postApiPath(), {
        method: 'POST',
        body: JSON.stringify({
          room,
          token,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return res(response.json());
          }
          return response;
        })
        .then((error) => {
          return rej(error);
        });
    });
  }

  delete(room: string, token: string): Promise<any> {
    return new Promise((res, rej) => {
      fetch(this.deleteApiPath(room), {
        method: 'DELETE',
        keepalive: true,
        body: JSON.stringify({
          token,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return res(response.json());
          }
          return response;
        })
        .then((error) => {
          return rej(error);
        });
    });
  }
}
