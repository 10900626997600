import { useRef } from 'react';

type WS<Message> = {
  get(): WebSocket | undefined;
  connect(): void;
  send(data: Message): void;
};

export const useWS = <Message>(
  uri: string,
  protocol: string,
  callback: {
    handleWSOpen(): any;
    handleWSClose(event: CloseEvent): any;
    handleWSMessage(event: MessageEvent): any;
  },
): WS<Message> => {
  const ws = useRef<WebSocket>();

  function get() {
    return ws.current;
  }

  function connectToWS() {
    ws.current = new WebSocket(uri, protocol);
    return ws.current;
  }

  function connect() {
    let ws = get();
    if (ws) {
      ws.removeEventListener('open', callback.handleWSOpen);
      ws.removeEventListener('close', callback.handleWSClose);
      ws.removeEventListener('message', callback.handleWSMessage);
      ws = undefined;
    }

    ws = connectToWS();
    ws.addEventListener('open', callback.handleWSOpen);
    ws.addEventListener('close', callback.handleWSClose);
    ws.addEventListener('message', callback.handleWSMessage);
  }

  function send(data: Message) {
    if (!ws.current) {
      return;
    }
    if (ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(data));
    } else {
      ws.current.close();
      console.error('接続を切断しました');
    }
  }

  return {
    get,
    connect,
    send,
  };
};
