import React from 'react';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';

type Props = {
  url: string;
  goMap: () => void;
};

const VtourButton = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    button: {
      marginTop: '16px',
      width: '240px',
      lineHeight: '32px',
      borderRadius: '25px',
      border: 0,
      fontSize: '14px',
      fontWeight: 'bold',
    },
    enabled: {
      backgroundColor: '#fbb63f',
      color: '#424242',
    },
    disabled: {
      cursor: 'not-allowed',
    },
  };

  const handleClick = React.useCallback(() => {
    props.goMap();
  }, [props]);

  return (
    <Button
      style={
        !!!props.url
          ? { ...style.button, ...style.disabled }
          : { ...style.button, ...style.enabled }
      }
      variant="contained"
      onClick={handleClick}
      disabled={!!!props.url}>
      <FormattedMessage id="VtourButton.Enter" />
    </Button>
  );
});

export default VtourButton;
