import React from 'react';
import { Helmet } from 'react-helmet';

import { MetaDataLocalized } from '../../domains/map';
import * as Constants from '../constants';

type Props = {
  metaData: MetaDataLocalized;
  isGuideMode: boolean;
};

const Html = React.memo((props: Props) => {
  const [title, setTitle] = React.useState(
    `${Constants.Service} ${Constants.Title}`,
  );
  const [description, setDescription] = React.useState(Constants.Description);
  const [thumbnail, setThumbnail] = React.useState(Constants.Thumbnail);
  React.useEffect(() => {
    const title = props.isGuideMode ? Constants.TourTitle : Constants.Title;
    if (props.metaData?.title) {
      setTitle(`${props.metaData?.title} - ${Constants.Service} ${title}`);
    }
    if (props.metaData?.description) {
      setDescription(props.metaData.description);
    }
    if (props.metaData?.thumbnail) {
      setThumbnail(props.metaData.thumbnail);
    }
  }, [props.metaData, props.isGuideMode]);

  const MetaData = props.metaData ? (
    <>
      <Helmet
        meta={[
          {
            property: 'og:title',
            content: title,
          },
          {
            property: 'og:image',
            content: thumbnail,
          },
          {
            name: 'twitter:title',
            content: title,
          },
          {
            property: 'og:image',
            content: thumbnail,
          },
          {
            name: 'description',
            content: description,
          },
        ]}>
        <title>{title}</title>
      </Helmet>
    </>
  ) : null;

  return MetaData;
});

export default Html;
