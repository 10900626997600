import React from 'react';
import GpsFixed from '@material-ui/icons/GpsFixed';
import GpsOffIcon from '@material-ui/icons/GpsOff';

type Props = {
  isDisabled: boolean;
  isSharing: boolean;
  controlLocate: () => void;
};

const LocateButton = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    button: {
      position: 'absolute',
      bottom: '16px',
      left: '16px',
      width: '40px',
      height: '40px',
      boxSizing: 'border-box',
      textAlign: 'center',
      padding: 0,
      boxShadow: '0 0 0 4px rgba(255,255,255,0.5)',
      filter: 'drop-shadow(0px 0 4px #333)',
      border: 0,
      borderRadius: '50%',
      backgroundColor: '#fff',
      cursor: 'pointer',
    },
  };

  const { isDisabled, isSharing, controlLocate } = props;

  const handleClick = React.useCallback(() => {
    controlLocate();
  }, [controlLocate]);

  const button = isSharing ? (
    <button onClick={handleClick} style={style.button} disabled={isDisabled}>
      <GpsOffIcon />
    </button>
  ) : (
    <button onClick={handleClick} style={style.button} disabled={isDisabled}>
      <GpsFixed />
    </button>
  );

  return button;
});

export default LocateButton;
