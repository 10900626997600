import { CharacterType } from '../domains/user/character';

export const Service = 'Stroly LAB';

export const Title = 'Virtual Map';

export const TourTitle = 'Vitual Tour';

export const Description =
  'Stroly LAB Virtual Map where all maps of Stroly.com can be experienced in virtual. Also supports communication with multiple members using the group function.';

export const Thumbnail = `${process.env.REACT_APP_SITE_URL}/ogp.jpg`;

export const IllustmapRootURL = 'https://ods3.illustmap.org';

export const DeportedUserName = '___deported_user___';

export const VTourCharactersData = [
  {
    type: CharacterType.StudentMan,
    image: '/images/student_man.png',
    width: '40',
  },
  {
    type: CharacterType.StudentMan2,
    image: '/images/student_man2.png',
    width: '40',
  },
  {
    type: CharacterType.StudentWoman,
    image: '/images/student_woman.png',
    width: '40',
  },
  {
    type: CharacterType.Maiko,
    image: '/images/maiko.png',
    width: '40',
  },
  {
    type: CharacterType.DogVtour,
    image: '/images/dog_vtour.png',
    width: '40',
  },
  {
    type: CharacterType.Cat,
    image: '/images/cat.png',
    width: '40',
  },
  {
    type: CharacterType.Monster,
    image: '/images/monster.png',
    width: '40',
  },
  {
    type: CharacterType.Robot,
    image: '/images/robot.png',
    width: '40',
  },
  {
    type: CharacterType.Alien,
    image: '/images/alien.png',
    width: '48',
  },
  {
    type: CharacterType.Navi,
    image: '/images/guide.png',
    width: '40',
  },
];

export const CharactersData = [
  {
    type: CharacterType.Dog,
    image: '/images/dog.gif',
    icon: '/images/icons/dog.png',
    width: '30',
  },
  {
    type: CharacterType.Tigercat,
    image: '/images/tigercat.gif',
    icon: '/images/icons/tigercat.png',
    width: '58',
  },
  {
    type: CharacterType.Blackcat,
    image: '/images/blackcat.gif',
    icon: '/images/icons/blackcat.png',
    width: '58',
  },
  {
    type: CharacterType.Whitecat,
    image: '/images/whitecat.gif',
    icon: '/images/icons/whitecat.png',
    width: '34',
  },

  {
    type: CharacterType.Bear,
    image: '/images/bear.gif',
    icon: '/images/icons/bear.png',
    width: '30',
  },
  {
    type: CharacterType.Balloon,
    image: '/images/balloon.gif',
    icon: '/images/icons/balloon.png',
    width: '18',
  },
  {
    type: CharacterType.Baby,
    image: '/images/baby.gif',
    icon: '/images/icons/baby.png',
    width: '34',
  },
  {
    type: CharacterType.Girl,
    image: '/images/girl.gif',
    icon: '/images/icons/girl.png',
    width: '32',
  },
  {
    type: CharacterType.Boy,
    image: '/images/boy.gif',
    icon: '/images/icons/boy.png',
    width: '28',
  },
];
