import React, { useState, useEffect, useRef, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IntlProvider } from 'react-intl';

import { ja, en } from '../core/i18n/vmap';
import { useLang } from './hooks/useLang';
import { updateUser } from '../core/reducer';
import { MapService } from '../domains/map';
import { UserType } from '../domains/user/user.model';
import { StateContext, DispatchContext } from '../core/contexts';

import VmapContainer from './VmapContainer';
import Html from '../core/components/Html';

export type GuideOptions = {
  isGuideMode: boolean;
  guideToken: number;
  limit: number;
  isCommentEnabled: boolean;
  isEmojiEnabled: boolean;
  isCommentListEnabled: boolean;
  isLocateEnabled: boolean;
};

interface Props extends RouteComponentProps {}

const AppContainer = (props: Props) => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const mapService = useRef(new MapService(dispatch));

  const [mapID, setMapID] = useState<string>();
  const [protocol, setProtocol] = useState<string>();

  const [guideOptions, setGuideOptions] = useState<GuideOptions>({
    isGuideMode: false,
    guideToken: NaN,
    limit: 20,
    isCommentEnabled: true,
    isEmojiEnabled: true,
    isCommentListEnabled: true,
    isLocateEnabled: true,
  });
  useEffect(() => {
    const path = props.location.pathname.split('/');

    if (path[1] === 'vtour') {
      let param;
      try {
        param = atob(path[2]).split(',');
      } catch {
        throw new Error('Forbidden error occurred.');
      }

      const [
        guideToken,
        mapID,
        limit,
        isCommentEnabled,
        isEmojiEnabled,
        isCommentListEnabled,
        isLocateEnabled,
      ] = param;

      if (path[3] && path[3] !== guideToken) {
        throw new Error('Forbidden error occurred.');
      }

      if (path[3] && path[3] === guideToken) {
        dispatch(
          updateUser({
            type: UserType.Guide,
            token: Number(path[3]),
          }),
        );
      }

      setMapID(mapID);
      setProtocol(guideToken + mapID);

      const guideOptions = {
        isGuideMode: true,
        guideToken: Number(guideToken),
        limit: Number(limit),
        isCommentEnabled: !!Number(isCommentEnabled),
        isEmojiEnabled: !!Number(isEmojiEnabled),
        isCommentListEnabled: !!Number(isCommentListEnabled),
        isLocateEnabled: !!Number(isLocateEnabled),
      } as GuideOptions;
      setGuideOptions(guideOptions);

      return;
    }

    const mapID = (props.match.params as any).mapid || '1594882114';
    const room = props.location.search.match(/room=([^&#]*)/);
    const protocol = room ? room[1] + mapID : state.user.token + mapID;
    setMapID(mapID);
    setProtocol(protocol);
  }, []);

  useEffect(() => {
    if (state.user.token) {
      gtag('set', { user_id: state.user.token });
    }
  }, [state.user.token]);

  const Vmap =
    mapID && protocol && state.user.token ? (
      <VmapContainer
        history={props.history}
        mapService={mapService.current}
        guideOptions={guideOptions}
        mapID={mapID}
        protocol={protocol}
      />
    ) : null;

  const lang = useLang({ ja, en });

  return (
    <IntlProvider locale={lang.language} messages={lang.message}>
      <Html metaData={state.metaData} isGuideMode={guideOptions?.isGuideMode} />
      {Vmap}
    </IntlProvider>
  );
};

export default AppContainer;
