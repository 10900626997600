import React from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { UserType } from '../../domains/user/user.model';

import './styles/nameForm.scss';

type Props = {
  intl: IntlShape;
  name: string;
  userType: UserType;
  isSmallDisplay: boolean;
  handleChange: (value: string) => void;
};

const ConnectionNameForm = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    text: {
      color: '#666',
      fontSize: '12px',
      fontWeight: 'bold',
      marginRight: '12px',
    },
    required: {
      color: '#f60',
    },
    input: {
      color: '#424242',
      padding: '0 8px',
      width: props.isSmallDisplay ? '120px' : '184px',
      border: '1px solid #dbdbdb',
      height: '32px',
      fontSize: '16px',
    },
  };

  const language = window.navigator.language;

  const handleChange = React.useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      props.handleChange(event.currentTarget.value);
    },
    [props],
  );

  const placeholder = props.intl.formatMessage({
    id:
      props.userType === UserType.Guide
        ? 'ConnectionNameForm.Guidename'
        : 'ConnectionNameForm.Nickname',
  });

  return (
    <div>
      <span style={style.text}>
        <span style={style.required}>＊</span>
        {props.userType === UserType.Guide ? (
          <FormattedMessage id="ConnectionNameForm.Guidename" />
        ) : (
          <FormattedMessage id="ConnectionNameForm.Nickname" />
        )}
      </span>
      <input
        className={`name-input ${language !== 'ja' && 'en'}`}
        maxLength={16}
        placeholder={placeholder}
        type="text"
        value={props.name}
        onChange={(event) => handleChange(event)}
        style={style.input}
      />
    </div>
  );
});

export default injectIntl(ConnectionNameForm);
