import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  participants: number;
  limit: number;
  isMobile: boolean;
};

const ConnectionParticipants = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    p: {
      fontSize: '14px',
      margin: '8px 0',
      textAlign: 'center',
    },
    participants: {
      fontSize: '18px',
    },
    maximum: {
      color: '#666',
    },
  };

  const participants = props.participants ? (
    <div>
      <p style={style.p}>
        <FormattedMessage id="ConnectionParticipants.Current" />{' '}
        <span style={style.participants}>{props.participants}</span>{' '}
        <FormattedMessage id="ConnectionParticipants.PeopleJA" />
        {props.isMobile && <br />}
        <span style={style.maximum}>
          <FormattedMessage
            id="ConnectionParticipants.Maximum"
            values={{
              limit: props.limit,
            }}
          />
        </span>
      </p>
    </div>
  ) : null;

  return participants;
});

export default ConnectionParticipants;
