import Stroly from 'stroly-js';
import React from 'react';
import * as L from 'leaflet';

import { setLandmarkCategories, setLandmarkContnet } from '../core/reducer';
import { Landmark } from '../domains/map/map.model';
import { LandmarkMarker } from '../domains/marker/landmark-marker';

export class LandmarkService {
  private ladmarkCategories = new Map<string, { [key: string]: string }>([]);
  private readonly landmarks = new Map<number, { icon: any; marker: L.Marker }>(
    [],
  );
  private readonly landmarkLayers = new Map<string, L.FeatureGroup>([]);
  private selectedLandmarkId!: number | undefined;

  constructor(
    private mapID: string,
    private stroly: Stroly,
    private dispatch: React.Dispatch<any>,
  ) {}

  getLandmarkCategories(): Map<string, { [key: string]: string }> {
    return this.ladmarkCategories;
  }

  init() {
    const landmarkCategories = this.stroly.getLandmarkCategories();

    landmarkCategories.forEach((category) => {
      this.ladmarkCategories.set(category.category, category.name);
      this.landmarkLayers.set(
        category.category,
        // L.FeatureGroupで指定するとランドマークの一部が表示されないので、
        // this.stroly.L.FeatureGroupとしている
        new this.stroly.L.FeatureGroup(),
      );
      this.landmarkLayers.get(category.category)?.addTo(this.stroly.map);

      category.landmark.forEach((landmark: Landmark) => {
        const xy = {
          lat: landmark.x,
          lng: landmark.y,
        };

        const landmarkMarker = new LandmarkMarker(
          this.stroly.L,
          xy,
          category.icon,
        );
        landmarkMarker.marker.on('click', () => {
          this.dispatch(
            setLandmarkContnet({
              id: landmark.id,
              name: landmark.name,
              description: landmark.description,
              xy: {
                lat: landmark.x,
                lng: landmark.y,
              },
            }),
          );
          this.setSelectedLandmarkMarker(landmark.id);

          gtag('event', 'click', {
            event_category: 'landmark',
            event_label: JSON.stringify([
              this.mapID,
              landmark.id,
              landmark.name,
            ]),
          });
        });

        if (!this.landmarks.has(landmark.id)) {
          this.landmarks.set(landmark.id, {
            icon: category.icon,
            marker: landmarkMarker.marker,
          });
        }

        const landmarkLayer = this.landmarkLayers.get(category.category);
        if (landmarkLayer && !landmarkLayer.hasLayer(landmarkMarker.marker)) {
          landmarkLayer.addLayer(landmarkMarker.marker);
          this.landmarkLayers.set(category.category, landmarkLayer);
        }
      });
    });

    this.dispatch(setLandmarkCategories(this.ladmarkCategories));
  }

  displayByCategory(category: string) {
    this.landmarkLayers.forEach((landmarkLayer) => {
      this.stroly.map.removeLayer(landmarkLayer);

      if (category === '__all__') {
        landmarkLayer.addTo(this.stroly.map);
      }
    });

    const landmarkLayer = this.landmarkLayers.get(category);
    if (landmarkLayer) {
      landmarkLayer.addTo(this.stroly.map);
    }
  }

  setSelectedLandmarkMarker(landmarkId?: number) {
    if (this.selectedLandmarkId) {
      const secelctedLandmark = this.landmarks.get(this.selectedLandmarkId);
      if (secelctedLandmark) {
        const icon = LandmarkMarker.createIcon(
          secelctedLandmark.icon?.iconStyle?.icon,
        );
        secelctedLandmark.marker.setIcon(icon);
        this.selectedLandmarkId = undefined;
      }
    }

    if (landmarkId) {
      const landmark = this.landmarks.get(landmarkId);
      if (landmark) {
        const icon = LandmarkMarker.createIcon(
          landmark.icon?.iconStyle?.selectedIcon,
          true,
        );
        landmark.marker.setIcon(icon);
        this.selectedLandmarkId = landmarkId;
      }
    }
  }
}
