export enum CharacterType {
  Guide = 'guide',
  Alien = 'alien',
  Cat = 'cat',
  DogVtour = 'dog_vtour',
  Maiko = 'maiko',
  Monster = 'monster',
  Navi = 'navi',
  Robot = 'robot',
  StudentMan = 'student_man',
  StudentMan2 = 'student_man2',
  StudentWoman = 'student_woman',
  Dog = 'dog',
  Boy = 'boy',
  Girl = 'girl',
  Baby = 'baby',
  Balloon = 'balloon',
  Bear = 'bear',
  Blackcat = 'blackcat',
  Tigercat = 'tigercat',
  Whitecat = 'whitecat',
}

export class Character {
  private readonly name: string;
  private readonly characterType: CharacterType;

  constructor(name: string, characterType: CharacterType) {
    this.name = name;
    this.characterType = characterType;
  }

  getName() {
    return this.name;
  }

  getCharacterType() {
    return this.characterType;
  }

  getIconSize(characterType: CharacterType) {
    switch (characterType) {
      case CharacterType.Guide:
        return [70 / 1.3, 120 / 1.3];
      case CharacterType.StudentMan:
        return [40, 70];
      case CharacterType.StudentMan2:
        return [40, 70];
      case CharacterType.StudentWoman:
        return [40, 70];
      case CharacterType.Navi:
        return [40, 70];
      case CharacterType.Maiko:
        return [40, 70];
      case CharacterType.DogVtour:
        return [40, 55];
      case CharacterType.Cat:
        return [40, 50];
      case CharacterType.Monster:
        return [70, 70];
      case CharacterType.Robot:
        return [40, 45];
      case CharacterType.Alien:
        return [40, 70];
      case CharacterType.Boy:
        return [28, 78];
      case CharacterType.Girl:
        return [32, 68];
      case CharacterType.Baby:
        return [34, 48];
      case CharacterType.Balloon:
        return [18, 44];
      case CharacterType.Bear:
        return [30, 50];
      case CharacterType.Dog:
        return [30, 48];
      case CharacterType.Blackcat:
        return [58, 46];
      case CharacterType.Tigercat:
        return [58, 48];
      case CharacterType.Whitecat:
        return [34, 38];
    }
  }
}
