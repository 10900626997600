import * as L from 'leaflet';

import * as Constants from '../../core/constants';
import { LatLng } from '../map/map.model';

export class LandmarkMarker {
  readonly marker: L.Marker;

  constructor(L: any, latlng: LatLng, icon: any) {
    this.marker = new L.marker([latlng.lat, latlng.lng], {
      icon: LandmarkMarker.createIcon(icon?.iconStyle?.icon),
      draggable: false,
      riseOnHover: true,
      riseOffset: 2000,
      zIndexOffset: 1000,
    });
  }

  static createIcon(icon: any, isSelected = false) {
    const iconUrl = icon
      ? `${Constants.IllustmapRootURL}${icon.href.replace(/\.\//gi, '/')}`
      : isSelected
      ? '/images/landmark-blue.png'
      : '/images/landmark-red.png';

    const iconSize = icon
      ? [icon.width, icon.height]
      : ([50 / 2, 60 / 2] as any);

    const iconAnchor = icon
      ? [icon.hotSpotPxX, icon.hotSpotPxY]
      : ([50 / 4, 60 / 2] as any);

    return new L.Icon({
      className: isSelected ? 'landmark-icon-selected' : 'landmark-icon',
      iconUrl,
      iconSize,
      iconAnchor,
    });
  }
}
