import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { GuideOptions } from '../../containers/AppContainer';
import { UserType } from '../../domains/user/user.model';

type Props = {
  startDate: moment.Moment | undefined;
  guideOptions: GuideOptions;
  userType: UserType;
  isMobile: boolean;
};

const ConnectionStartDate = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    text: {
      fontSize: '14px',
      textAlign: 'center',
      marginTop: '0',
      marginBottom: '0',
    },
    caption: {
      color: props.isMobile ? '#666' : '#000',
      display: props.isMobile ? 'block' : 'inline',
      marginRight: props.isMobile ? 0 : '8px',
    },
    date: {
      margin: '0 0 16px',
      fontSize: '18px',
    },
  };

  const startDate =
    props.guideOptions.isGuideMode && props.startDate ? (
      <div>
        <p style={style.text}>
          <span style={style.caption}>
            <FormattedMessage id="ConnectionStartDate.DateAndTime" />
          </span>
          <span style={style.date}>
            <FormattedMessage
              id="ConnectionStartDate.StartDateTime"
              values={{
                year: props.startDate.format('YYYY'),
                month: props.startDate.format('MM'),
                day: props.startDate.format('DD'),
                time: props.startDate.format('HH:mm'),
              }}
            />
          </span>
        </p>
      </div>
    ) : null;

  return startDate;
});

export default ConnectionStartDate;
