import React from 'react';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';

type Props = {
  isDialogOpen: boolean;
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
};

const Dialog = (props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    close: {
      position: 'absolute',
      top: '8px',
      right: '8px',
      cursor: 'pointer',
    },
  };

  const handleClose = () => {
    props.setIsDialogOpen(false);
  };

  return (
    <MuiDialog
      open={props.isDialogOpen}
      onClose={handleClose}
      scroll={'paper'}
      style={style.dialog}>
      <MuiDialogContent style={style.body} dividers={true}>
        <CloseIcon onClick={handleClose} style={style.close} />
        {props.children}
      </MuiDialogContent>
    </MuiDialog>
  );
};

export default Dialog;
