import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Drawer from '@material-ui/core/Drawer';

import { updateIsLandmarkOpen } from '../../core/reducer';
import * as Constants from '../../core/constants';
import './styles/landmark.scss';
import { UserType } from '../../domains/user/user.model';
import { LatLng, LandmarkContentLocalized } from '../../domains/map/map.model';

type Props = {
  landmark: LandmarkContentLocalized;
  userType: UserType;
  isLandmarkOpen: boolean;
  isMobile: boolean;
  dispatch: React.Dispatch<any>;
  panToLandmark: (latlng: LatLng) => void;
  setSelectedLandmarkMarker: (landmarkId?: number) => void;
};

const Landmark = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    drawer: {
      zIndex: 1001,
      position: 'relative',
    },
    div: {
      boxSizing: 'border-box',
      borderTop: '4px solid #000',
      padding: '4px 16px 0',
      borderBottom: '1px solid #bdbdbd',
    },
    name: {
      margin: 0,
      paddingBottom: '8px',
      paddingRight: '32px',
      fontSize: '18px',
    },
    close: {
      position: 'absolute',
      right: '0',
      display: 'inline-block',
      width: '32px',
      lineHeight: '32px',
      cursor: 'pointer',
      backgroundColor: '#000',
      textAlign: 'center',
    },
    buttons: {
      margin: 0,
      padding: '0 0 8px',
      textAlign: 'center',
    },
    icon: {
      verticalAlign: 'text-top',
    },
    mobileButton: {
      display: 'inline-block',
      margin: '2px 8px 0 0',
      width: '32px',
      height: '32px',
      textAlign: 'center',
      borderRadius: '50%',
      color: '#fff',
      backgroundColor: '#ec9874',
      boxShadow: '0 0 2px #333',
      paddingTop: '8px',
      boxSizing: 'border-box',
    },
    button: {
      display: 'inline-block',
      textAlign: 'center',
      width: '280px',
      cursor: 'pointer',
      margin: 0,
      padding: '8px 0',
      color: '#fff',
      fontSize: '14px',
      backgroundColor: '#ec9874',
      borderRadius: '8px',
    },
    iframe: {
      margin: 0,
      padding: 0,
      border: 0,
      width: '100%',
      height: props.isMobile ? 'calc(100% - 51px)' : 'calc(100% - 89px)',
      boxSizing: 'border-box',
    },
  };

  const [srcDoc, setSrcDoc] = React.useState('');

  useEffect(() => {
    if (!props.landmark) {
      return;
    }
    const description = props.landmark.description;
    const replacedBySingleQuotation = description
      ? description.replace(/='\./gi, `='${Constants.IllustmapRootURL}`)
      : '';
    const replacedByDubbleQuotation = replacedBySingleQuotation
      ? replacedBySingleQuotation.replace(
          /="\./gi,
          `="${Constants.IllustmapRootURL}`,
        )
      : '';
    setSrcDoc(replacedByDubbleQuotation);

    if (!props.isLandmarkOpen) {
      props.dispatch(updateIsLandmarkOpen(true));
    }
  }, [props.landmark]);

  const handleMovieClick = useCallback(() => {
    props.panToLandmark(props.landmark.xy);
  }, [props]);

  const handleCloseClick = useCallback(() => {
    if (props.isLandmarkOpen) {
      props.setSelectedLandmarkMarker(props.landmark.id);
      setSrcDoc('');
      props.dispatch(updateIsLandmarkOpen(false));
    }
  }, [props]);

  return (
    <Drawer
      className="landmark"
      style={style.drawer}
      anchor={props.isMobile ? 'bottom' : 'left'}
      open={props.isLandmarkOpen}
      ModalProps={{
        disablePortal: false,
        disableEnforceFocus: true,
      }}>
      <div onClick={handleCloseClick} style={style.close}>
        <img src="/images/icons/close.svg" alt="close" width="12" />
      </div>

      <div style={style.div}>
        <h2 style={style.name}>
          {props.isMobile ? (
            <div style={style.mobileButton} onClick={handleMovieClick}>
              <img
                src="/images/icons/spot.svg"
                alt="spot"
                width="12"
                style={style.icon}
              />
            </div>
          ) : (
            <LocationOnIcon style={{ verticalAlign: 'top' }} />
          )}

          {props.landmark.name}
        </h2>
        {!props.isMobile ? (
          <div style={style.buttons}>
            <div style={style.button} onClick={handleMovieClick}>
              <img
                src="/images/icons/spot.svg"
                alt="spot"
                width="12"
                style={style.icon}
              />{' '}
              <FormattedMessage id="Landmark.Move" />
            </div>
          </div>
        ) : null}
      </div>

      <iframe title="landmark" srcDoc={srcDoc} style={style.iframe}></iframe>
    </Drawer>
  );
});

export default Landmark;
