import * as L from 'leaflet';

import { LatLng } from '../map/map.model';
import { Character } from '../user/character';

export class LocationMarker extends L.Marker {
  constructor(latlng: LatLng, color: string[], character: Character) {
    super([latlng.lat, latlng.lng], {
      icon: LocationMarker.createIcon(color, character),
      draggable: false,
      riseOnHover: true,
      riseOffset: -2000,
    });
  }

  static getIsEmoji(message: string | undefined): boolean {
    if (!message) {
      return false;
    }
    return /^(👍|❤️|❗️)$/.test(message);
  }

  static createIcon(color: string[], character: Character, message?: string) {
    const MerkerWidth = 176;

    const style = {
      marker: `
        position: relative;
        margin: 0;
        padding: 0;
        width: ${MerkerWidth}px;;
        text-align: center;
      `,
      popup: `
        position: fixed;
        bottom: 26px;
        width: ${MerkerWidth}px;
        text-aling: center;
        filter: drop-shadow(0px 2px 4px #424242);
      `,
      comment: `
        display: inline-block;
        text-align: left;
        border-radius: 8px;
        background-color: #fff;
        color: #000;
        padding: 4px 12px;
        opacity: 1;
        word-wrap: break-word;
        hyphens: auto;
        font-size: 14px;
        font-weight: bold;
        max-width: ${MerkerWidth}px;
      `,
      message: `
        font-size: 26px;
      `,
      bubble: `
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -6px;
        border: 6px solid transparent;
        opacity: 1;
        border-top: 6px solid #fff;
      `,
      character: `
        display: block;
        width: 28px;
        height: 28px;
        box-shadow: 0 0 0 4px ${color[1]};
        border-radius: 50%;
        border: 2px solid #fff;
        vertical-align: middle;
        background-color: ${color[0]};
        background-image: url(/images/${character.getCharacterType()}.png);
        background-size: cover;
        margin: 0 auto 8px;
        filter: drop-shadow(0px 2px 2px #424242);
      `,
      name: `
        border-radius: 4px;
        margin: 0;
        padding: 2px 16px;
        text-align: center;
        color: #fff;
        width: 100%;
        background-color: ${color[0]};
        box-shadow: 0 2px 4px #424242;
        font-weight: bold;
      `,
    };

    const comment = LocationMarker.getIsEmoji(message)
      ? `<span style="${style.message}">${message}</span>`
      : LocationMarker.encodeMessage(message);

    const popup = message
      ? `<div style="${style.popup}">
          <div style="${style.comment}">${comment}</div>
          <div style="${style.bubble}"></div>
        </div>`
      : '';

    return L.divIcon({
      className: 'marker-icon',
      // iconAnchor: [14, 14],
      iconAnchor: [MerkerWidth / 2, 26],
      popupAnchor: [0, 0],
      html: `
        <div style="${style.marker}">
          ${popup}
          <div style="${style.character}"></div>
          <span style="${style.name}">${character.getName()}</span>
        </div>
      `,
    });
  }

  static encodeMessage(string: String | undefined) {
    if (!string) {
      return string;
    }
    return string
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;');
  }
}
