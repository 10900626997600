import React, { useCallback } from 'react';
import Badge from '@material-ui/core/Badge';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

type Props = {
  unReaded: number;
  isChatOpen: boolean;
  handleChatButtonClick: () => void;
  children: React.ReactChild;
};

const CommentButton = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    wrapper: {
      width: '240px',
      margin: 0,
      borderRadius: '4px',
    },
    summary: {
      minHeight: 'auto',
    },
    label: {
      cursor: 'pointer',
      backgroundColor: '#666',
      boxSizing: 'border-box',
      padding: '0 16px',
      display: 'flex',
      justifyContent: 'space-between',
      width: '240px',
      lineHeight: '32px',
      height: '32px',
      borderRadius: '0 0 4px 4px',
    },
    text: {
      color: '#fff',
      marginRight: '8px',
      fontSize: '12px',
    },
    details: {
      maxHeight: 'calc(50vh - 184px)',
      height: '100%',
      overflowY: 'scroll',
    },
  };

  const commentToggle = props.isChatOpen ? (
    <>
      <span style={style.text}>コメントリストを畳む</span>
      <img src="/images/icons/arrow_top.svg" width="12" alt=">" />
    </>
  ) : (
    <>
      <span style={style.text}>コメントリストを開く</span>
      <img src="/images/icons/arrow_bottom.svg" width="12" alt="<" />
    </>
  );

  const handleClick = useCallback(() => {
    gtag('event', 'click', {
      event_category: 'button',
      event_label: props.isChatOpen ? 'Close Comments' : 'Open Comments',
    });

    props.handleChatButtonClick();
  }, [props]);

  return (
    <Accordion expanded={props.isChatOpen} style={style.wrapper}>
      <AccordionSummary
        aria-controls="panel1a-content"
        style={style.summary}
        onClick={handleClick}>
        <Badge
          color="secondary"
          badgeContent={props.unReaded}
          // variant="dot"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
          <div style={style.label}>{commentToggle}</div>
        </Badge>
      </AccordionSummary>
      <AccordionDetails style={style.details}>
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
});

export default CommentButton;
