import React from 'react';

import { EmojiName } from '../../domains/ws/emoji';

type Props = {
  emoji: EmojiName;
  label: string;
  handleSendEmoji: (emoji: EmojiName) => void;
};

const CommentEmoji = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    emojiButton: {
      boxSizing: 'border-box',
      textAlign: 'center',
    },
    emoji: {
      boxSizing: 'border-box',
      display: 'inline-block',
      transform: 'scale(0.8)',
      width: '30px',
      lineHeight: '40px',
      height: '40px',
      fontSize: '24px',
      cursor: 'pointer',
    },
  };

  const [isEnabled, setIsEnabled] = React.useState(true);
  const handleEmojiClick = React.useCallback(
    (emoji: EmojiName): void => {
      if (isEnabled) {
        props.handleSendEmoji(emoji);
        setIsEnabled(false);

        setTimeout(() => {
          setIsEnabled(true);
        }, 1000);
      }
    },
    [isEnabled, props],
  );

  return (
    <div style={style.emojiButton}>
      <span
        role="img"
        aria-label={props.label}
        style={style.emoji}
        onClick={() => handleEmojiClick(props.emoji)}>
        {props.emoji}
      </span>
    </div>
  );
});

export default CommentEmoji;
