import React, { useEffect } from 'react';
import { injectIntl, IntlShape } from 'react-intl';

import { LocationMessage } from '../../domains/ws/message.model';
import { MapService } from '../../domains/map';
import { updateIsDisabled, updateIsSharing } from '../../core/reducer';
import { Character, CharacterType } from '../../domains/user/character';
import { User, UserType } from '../../domains/user/user.model';
import * as Constants from '../../core/constants';

type Props = {
  intl: IntlShape;
  user: User;
  mapService: MapService;
  protocol: string;
  character: Character;
  mapID: string;
  isLogedIn: boolean;
  dispatch: React.Dispatch<any>;
  sendRemoveCentralMarker(isLeaving: boolean): void;
  sendDatatoWS(locationMassage: LocationMessage): void;
  children?: React.ReactNode | any;
};

const StrolyMap = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    div: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 0,
      overflow: 'hidden',
    },
    map: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 0,
    },
  };

  const {
    mapService,
    dispatch,
    protocol,
    user,
    character,
    mapID,
    isLogedIn,
    sendDatatoWS,
  } = props;
  const mapRef = React.useRef(null);
  let timer!: NodeJS.Timeout;

  useEffect(() => {
    mapService.initStrolyMap(mapRef.current, user.token, mapID);
  }, []);

  function handleLocationFound(event: L.LeafletEvent) {
    clearInterval(timer);
    dispatch(updateIsDisabled(true));

    console.log(
      `Get current location: ${event.latlng.lat}, ${event.latlng.lng}`,
    );

    const isInMap = mapService.getIsInMap(event.latlng);
    if (!isInMap) {
      const message = props.intl.formatMessage({
        id: 'StrolyMap.LocateButton.AlertMessage',
      });
      alert(message);
      mapService.stopGettingCurrentLocation();
      return;
    }

    props.sendRemoveCentralMarker(false);

    const xy = mapService.stroly.latLngToStroly({
      lat: event.latlng.lat,
      lng: event.latlng.lng > 180 ? event.latlng.lng - 360 : event.latlng.lng,
    });

    const locationMassage: LocationMessage = {
      room: protocol,
      token: user.token,
      color: user.color,
      id: new Date().getTime(),
      name: character.getName(),
      characterType: character.getCharacterType(),
      lat: xy.lat,
      lng: xy.lng,
      task: 'location',
    };
    sendDatatoWS(locationMassage);

    dispatch(updateIsSharing(true));

    const zoomScale = mapService.strolyMap.getZoom();
    const date = new Date().toISOString();
    gtag('event', 'found', {
      event_category: 'location',
      event_label: JSON.stringify([
        mapID,
        event.latlng.lat,
        event.latlng.lng,
        xy.lat,
        xy.lng,
        zoomScale,
        date,
      ]),
    });

    timer = setInterval(() => {
      sendDatatoWS(locationMassage);
    }, 10000);
  }

  function handleLocationStop() {
    clearInterval(timer);
    const locationMassage: LocationMessage = {
      room: protocol,
      token: user.token,
      color: user.color,
      id: new Date().getTime(),
      name: character.getName(),
      characterType: character.getCharacterType(),
      lat: NaN,
      lng: NaN,
      task: 'removeLocation',
    };
    sendDatatoWS(locationMassage);
  }

  function handleLocationError() {
    console.error('Failed to get the current location.');
    dispatch(updateIsSharing(false));
    dispatch(updateIsDisabled(false));
  }

  function handleCentralMove(event: any) {
    const locationMassage: LocationMessage = {
      room: protocol,
      token: user.token,
      color: user.color,
      id: new Date().getTime(),
      name: character.getName(),
      characterType: character.getCharacterType(),
      lat: event.lat,
      lng: event.lng,
      task: event.type,
    };
    if (user.type !== UserType.Browse) {
      sendDatatoWS(locationMassage);
    }
  }

  function handleDeportation(event: any) {
    if (user.type === UserType.Guide) {
      gtag('event', 'click', {
        event_category: 'vtour',
        event_label: 'deportation',
      });

      const message = props.intl.formatMessage({
        id: 'StrolyMap.DeportUser.ConfirmMessage',
      });
      const allowDeportation = window.confirm(message);

      if (allowDeportation) {
        const locationMassage: LocationMessage = {
          room: protocol,
          token: event.token,
          color: user.color,
          id: new Date().getTime(),
          name: Constants.DeportedUserName,
          characterType: CharacterType.Boy,
          lat: NaN,
          lng: NaN,
          task: 'removeCentralMarker',
        };
        sendDatatoWS(locationMassage);
      }
    }
  }

  useEffect(() => {
    if (isLogedIn) {
      // mapService.strolyMap.on('click', (event: L.LeafletEvent) => {
      //   const marker: Marker = {
      //     token,
      //     color,
      //     id: new Date().getTime(),
      //     lat: event.latlng.lat,
      //     lng: event.latlng.lng,
      //     task: 'put',
      //   };
      //   sendDatatoWS(JSON.stringify(marker));
      // });
      mapService.strolyMap.on('locationfound', handleLocationFound);
      mapService.strolyMap.on('locationstop', handleLocationStop);
      mapService.strolyMap.on('locationerror', handleLocationError);
      mapService.strolyMap.on('centralmove', handleCentralMove);
      mapService.strolyMap.on('deportation', handleDeportation);
    }

    return () => {
      mapService.strolyMap.off('locationfound', handleLocationFound);
      mapService.strolyMap.off('locationstop', handleLocationStop);
      mapService.strolyMap.off('locationerror', handleLocationError);
      mapService.strolyMap.off('centralmove', handleCentralMove);
      mapService.strolyMap.off('deportation', handleDeportation);
    };
  }, [isLogedIn, user.type]);

  return (
    <div style={style.div}>
      <div ref={mapRef} style={style.map}></div>
      {props.children}
    </div>
  );
});

export default injectIntl(StrolyMap);
