import React from 'react';
import { CharacterType } from '../../domains/user/character';

type Props = {
  handleCharacterSelected: (characterType: CharacterType) => void;
};

const ConnectionGuideImage = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    icon: {
      margin: '0 8px',
      width: '32px',
      height: '32px',
      backgroundImage: 'url(/images/guide.png)',
      backgroundColor: '#e7e7e7',
      backgroundSize: 'cover',
      borderRadius: '50%',
    },
  };

  React.useEffect(() => {
    props.handleCharacterSelected(CharacterType.Guide);
  }, []);

  return <div style={style.icon}></div>;
});

export default ConnectionGuideImage;
