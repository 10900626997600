import React, { useState, useEffect, useRef } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { History } from 'history';
import * as L from 'leaflet';
import moment from 'moment';

import { MapService, ActiveUser } from '../domains/map';
import { GuideOptions } from './AppContainer';
import { ConnectionsRepository } from '../repositories/connections.repository';
import {
  updateUser,
  updateIsDisabled,
  deleteComments,
  updateIsLoading,
  updateUserList,
} from '../core/reducer';
import { StateContext, DispatchContext } from '../core/contexts';
import { CharacterType, Character } from '../domains/user/character';
import { LocationMessage } from '../domains/ws/message.model';
import { UserType } from '../domains/user/user.model';
import * as Constants from '../core/constants';
import { useWS } from './hooks/useWS';

import ConnectionContainer from './ConnectionContainer';
import MapContainer from './MapContainer';

type Props = {
  intl: IntlShape;
  history: History;
  mapService: MapService;
  guideOptions: GuideOptions;
  mapID: string;
  protocol: string;
};

const VmapContainer = (props: Props) => {
  const state = React.useContext(StateContext);
  const dispatch = React.useContext(DispatchContext);

  const [name, setName] = useState<string>('');
  const [characterType, setCharacterType] = useState<CharacterType>(
    CharacterType.StudentMan,
  );
  const [character, setCharacter] = useState<Character>(
    new Character('', CharacterType.StudentMan),
  );

  const connectionsRepository = useRef(new ConnectionsRepository());

  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [exitUser, setExitUser] = useState<string>('');

  const isShareMode = useRef<boolean>(state.isSharing);
  useEffect(() => {
    isShareMode.current = state.isSharing;
  }, [state.isSharing]);

  /**
   * WebSocketの接続が開始された時の処理
   */
  function handleWSOpen() {
    dispatch(updateIsDisabled(false));
    if (isShareMode.current === false && isBrowseMode.current === false) {
      props.mapService.createCentralMarker(
        state.user.token,
        state.user.color,
        character,
      );
    }
  }
  /**
   * WebSocketの接続が切れた時の処理
   */
  function handleWSClose(event: CloseEvent) {
    console.log(
      'Socket for locaion is closed. Reconnect will be attempted in 2 second.',
      event.reason,
    );
    setTimeout(() => {
      locationWS.connect();
    }, 2000);
  }
  /**
   * WebSocketサーバーからメッセージを受け取った時の処理
   */
  function handleWSMessage(event: MessageEvent) {
    const movedUser = {
      ...JSON.parse(event.data),
      timestamp: moment(),
    } as ActiveUser;
    const activeUser = props.mapService.activeUsers[movedUser.token];
    const isMe = state.user.token === movedUser.token;

    if (state.user.token > movedUser.id) return;

    switch (movedUser.task) {
      case 'put':
        break;
      case 'move':
        break;
      case 'remove':
        break;

      /**
       * 参加者の現在地が移動した時
       */
      case 'location':
        if (
          !props.mapService.locations[movedUser.token] &&
          movedUser.token === state.user.token
        ) {
          props.mapService.panTo(movedUser.lat, movedUser.lng);
        }
        props.mapService.putLocationMarker(movedUser);

        if (
          !activeUser ||
          movedUser.timestamp.diff(activeUser?.timestamp) > 1000 * 60
        ) {
          props.mapService.activeUsers = {
            ...props.mapService.activeUsers,
            [movedUser.token]: movedUser,
          };
          dispatch(updateUserList(props.mapService.activeUsers));
        }

        dispatch(updateIsDisabled(false));
        break;
      case 'removeLocation':
        props.mapService.removeLocationMarker(movedUser.token);
        dispatch(updateIsDisabled(false));
        break;
      /**
       * 参加者のキャラクターが移動した時
       */
      case 'centralmove':
        if (isMe) {
          sessionStorage.setItem('timestamp', moment().utc().toString());
        }

        if (
          !activeUser ||
          movedUser.timestamp.diff(activeUser?.timestamp) > 1000 * 60
        ) {
          props.mapService.activeUsers = {
            ...props.mapService.activeUsers,
            [movedUser.token]: movedUser,
          };
          dispatch(updateUserList(props.mapService.activeUsers));
        }

        props.mapService.moveCentralMarker(movedUser);
        break;
      case 'hideCentralMarker':
        props.mapService.removeCentralMarker(movedUser.token);
        break;
      case 'removeCentralMarker':
        props.mapService.removeCentralMarker(movedUser.token);
        props.mapService.removeLocationMarker(movedUser.token);
        delete props.mapService.activeUsers[movedUser.token];
        dispatch(updateUserList(props.mapService.activeUsers));
        if (!isMe && movedUser.name !== Constants.DeportedUserName) {
          setExitUser(movedUser.name);
          setIsSnackbarOpen(true);
        }

        if (isMe && movedUser.name === Constants.DeportedUserName) {
          dispatch(
            updateUser({
              type: UserType.Browse,
            }),
          );

          const message = props.intl.formatMessage({
            id: 'VmapContainer.DeportedUser.AlertMessage',
          });
          alert(message);

          dispatch(updateIsLoading(true));
          connectionsRepository.current
            .delete(props.protocol, String(state.user.token))
            .then(() => {
              dispatch(updateIsLoading(false));
            })
            .catch((error) => {
              dispatch(updateIsLoading(false));
              props.history.push(
                `/error/${props.protocol}`,
                props.history.location.pathname,
              );
            });
        }

        if (movedUser.name === Constants.DeportedUserName) {
          dispatch(deleteComments(movedUser.token));
        }
        break;
    }
  }
  const locationWS = useWS<LocationMessage>(
    `${process.env.REACT_APP_LOCATION_SHARING_SERVER}/location`,
    props.protocol,
    {
      handleWSOpen,
      handleWSClose,
      handleWSMessage,
    },
  );

  /** ログイン後の処理（地図画面表示前の処理） */
  const [isLogedIn, setIsLogedIn] = useState(false);
  function initVirtualMap() {
    if (!isLogedIn) {
      setIsLogedIn(true);
    }
    if (!locationWS.get()) {
      locationWS.connect();
    }
  }

  const isBrowseMode = useRef(false);
  useEffect(() => {
    if (state.user.type === UserType.Browse) {
      initVirtualMap();
      isBrowseMode.current = true;
    } else {
      isBrowseMode.current = false;
    }
  }, [state.user.type]);

  function handleNameChange(name: string) {
    setName(name);
    setCharacter(new Character(name, characterType));
  }

  function handleCharacterSelected(characterType: CharacterType) {
    setCharacterType(characterType);
    setCharacter(new Character(name, characterType));
  }

  function sendRemoveCentralMarker(isLeaving: boolean): void {
    const task = isLeaving ? 'removeCentralMarker' : 'hideCentralMarker';
    const locationMassage = {
      room: props.protocol as string,
      token: state.user.token,
      color: state.user.color,
      id: new Date().getTime(),
      name: character.getName(),
      characterType: character.getCharacterType(),
      lat: NaN,
      lng: NaN,
      task,
    };
    locationWS.send(locationMassage);
  }

  const isMobile = L.Browser.mobile;
  const isSmallDisplay = window.matchMedia('(max-width: 400px)').matches;

  return (
    <>
      <ConnectionContainer
        isLogedIn={isLogedIn}
        history={props.history}
        guideOptions={props.guideOptions}
        name={name}
        character={character}
        protocol={props.protocol}
        isMobile={isMobile}
        isSmallDisplay={isSmallDisplay}
        initVirtualMap={initVirtualMap}
        handleCharacterSelected={handleCharacterSelected}
        handleNameChange={handleNameChange}
      />
      <MapContainer
        mapService={props.mapService}
        guideOptions={props.guideOptions}
        mapID={props.mapID}
        name={name}
        characterType={characterType}
        character={character}
        protocol={props.protocol}
        isLogedIn={isLogedIn}
        isMobile={isMobile}
        isSmallDisplay={isSmallDisplay}
        isSnackbarOpen={isSnackbarOpen}
        exitUser={exitUser}
        sendRemoveCentralMarker={sendRemoveCentralMarker}
        setExitUser={setExitUser}
        setIsSnackbarOpen={setIsSnackbarOpen}
        sendToLocationWS={locationWS.send}
      />
    </>
  );
};

export default injectIntl(VmapContainer);
