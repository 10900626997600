import { useRef } from 'react';
import moment from 'moment';

export function useStartDatetime() {
  const startDatetime = useRef<moment.Moment>();

  function setStartDatetime(datetime: moment.Moment) {
    startDatetime.current = datetime;
  }

  /**
   * ツアー開始前かどうか取得する（現在は10分で設定）
   */
  function getIsBeforeTour(): boolean {
    if (!startDatetime.current) {
      return false;
    }
    const currentDate = moment();
    if (startDatetime.current.diff(currentDate) > 60 * 1000 * 10) {
      return true;
    }
    return false;
  }

  /**
   * ツアーが終了したかどうか取得する（現在は60分で設定）
   */
  function getIsAfterTour(): boolean {
    if (!startDatetime.current) {
      return false;
    }
    const currentDate = moment();
    if (currentDate.diff(startDatetime.current) > 60 * 1000 * 60) {
      return true;
    }
    return false;
  }

  return {
    startDatetime: startDatetime.current,
    setStartDatetime,
    getIsBeforeTour,
    getIsAfterTour,
  };
}
