import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';

type Props = {
  isGuideMode: boolean;
  isFull: boolean;
  handleBrowseModeClick: () => void;
};

const ConnectionBrowse = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    p: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: '14px',
      margin: '16px 16px 0',
      textAlign: 'left',
      color: '#e53935',
    },
    text: {
      fontSize: '12px',
      color: '#666',
      cursor: 'pointer',
      margin: '0',
    },
    button: {
      marginTop: '8px',
      marginBottom: '16px',
      width: '240px',
      lineHeight: '32px',
      borderRadius: '25px',
      fontSize: '14px',
      fontWeight: 'bold',
      backgroundColor: '#eab540',
      color: '#000',
    },
  };

  const button = props.isFull ? (
    <Button
      style={style.button}
      variant="contained"
      onClick={props.handleBrowseModeClick}>
      <FormattedMessage id="ConnectionBrowse.BrowseModeForVtour" />
    </Button>
  ) : (
    <>
      {props.isGuideMode ? (
        <p style={style.p}>
          <span>事前申込をしていない方は、下記よりご見学ください。</span>
        </p>
      ) : null}
      <p style={style.text} onClick={props.handleBrowseModeClick}>
        {props.isGuideMode ? (
          <FormattedMessage id="ConnectionBrowse.BrowseModeForVtour" />
        ) : (
          <FormattedMessage id="ConnectionBrowse.BrowseMode" />
        )}
      </p>
    </>
  );

  return button;
});

export default ConnectionBrowse;
