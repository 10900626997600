import React from 'react';

type Props = {
  children?: React.ReactNode;
};

const VtourOverlay = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    overlay: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      zIndex: 1200,
      backgroundColor: 'rgba(230,230,230,0.9)',
    },
  };

  return <div style={style.overlay}>{props.children}</div>;
});

export default VtourOverlay;
