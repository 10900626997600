import React from 'react';

type Props = {
  handleExitButtonClick(): void;
};

const ExitButton = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    exit: {
      position: 'absolute',
      bottom: '8px',
      right: '8px',
      textAlign: 'center',
      boxSizing: 'border-box',
      display: 'block',
      padding: 0,
      border: 0,
      backgroundColor: '#333',
      borderRadius: '50%',
      cursor: 'pointer',
      width: '32px',
      height: '32px',
    },
  };

  return (
    <button onClick={props.handleExitButtonClick} style={style.exit}>
      <img src="/images/icons/exit.svg" alt="send" width="16" />
    </button>
  );
});

export default ExitButton;
