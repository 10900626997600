import React from 'react';

import { CommentMessage } from '../../domains/ws/message.model';
import { GuideOptions } from '../../containers/AppContainer';
import './styles/comment.scss';

type Props = {
  token: number;
  isChatOpen: boolean;
  comments: CommentMessage[];
  guideOptions: GuideOptions;
  panToComment: (comment: CommentMessage) => void;
};

const CommentList = (props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    ul: {
      margin: 0,
      listStyleType: 'none',
      padding: 0,
      color: '#424242',
      width: '100%',
    },
    li: {
      padding: '8px 0',
      fontSize: '14px',
      lineHeight: '14px',
      borderBottom: '1px solid #ccc',
      wordWrap: 'break-word',
      hyphens: 'auto',
      display: 'flex',
    },
    commentBox: {
      width: 'calc(100% - 32px - 8px)',
      textAlign: 'left',
    },
    name: {
      fontSize: '12px',
      color: '#929292',
      margin: '0 0 4px',
    },
    commentArea: {
      margin: '0',
    },
    comment: {
      maxWidth: 'calc(100%)',
      textAlign: 'left',
      display: 'inline-block',
      wordWrap: 'break-word',
      hyphens: 'auto',
    },
    emoji: {
      display: 'inline-block',
      fontSize: '24px',
    },
    date: {
      color: '#9e9e9e',
      fontSize: '10px',
      lineHeight: '10px',
    } as React.CSSProperties,
  };

  const circleStyle = React.useCallback((comment: CommentMessage) => {
    return {
      display: 'inline-block',
      width: '32px',
      height: '32px',
      borderRadius: '50%',
      backgroundColor: comment.color[0],
      margin: comment.token === props.token ? '0 0 0 8px' : '0 8px 0 0',
      verticalAlign: 'middle',
      backgroundImage: `url('/images/${comment.characterType}.png')`,
      backgroundSize: 'cover',
    } as React.CSSProperties;
  }, []);

  const ref = React.useRef<HTMLDivElement>(null);
  const scrollToBottomOfList = React.useCallback(() => {
    ref!.current!.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, [ref]);

  React.useEffect(() => {
    if (props.isChatOpen) {
      setTimeout(() => {
        scrollToBottomOfList();
      }, 400);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.comments, props.isChatOpen]);

  const [selected, setSelected] = React.useState<string>();
  const handleClick = React.useCallback(
    (comment: CommentMessage, key: string) => {
      setSelected(key);
      props.panToComment(comment);
    },
    [props],
  );

  function getIsEmoji(message: string | undefined): boolean {
    if (!message) {
      return false;
    }
    return /^(👍|❤️|❗️)$/.test(message);
  }

  const comments = props.comments
    .filter((comment: CommentMessage) => {
      return (
        props.guideOptions.isCommentListEnabled ||
        (!props.guideOptions.isCommentListEnabled &&
          props.guideOptions.guideToken === comment.token)
      );
    })
    .map((comment: CommentMessage) => {
      const isEmoji = getIsEmoji(comment.comment);
      return (
        <li
          key={comment.id}
          style={
            comment.token === props.token
              ? {
                  ...style.li,
                  flexDirection: 'row-reverse',
                  textAlign: 'right',
                }
              : style.li
          }
          onClick={() => handleClick(comment, comment.id)}
          className={selected === comment.id ? 'selected' : 'non-selected'}>
          <span style={circleStyle(comment)}></span>
          <div style={style.commentBox}>
            {comment.token === props.token ? null : (
              <p style={style.name}>{comment.name}</p>
            )}
            <p style={style.commentArea}>
              <span
                style={
                  isEmoji
                    ? {
                        ...style.emoji,
                        marginTop:
                          comment.token === props.token ? '10px' : '4px',
                      }
                    : style.comment
                }>
                {comment.comment}
              </span>
            </p>
          </div>
        </li>
      );
    });

  return (
    <div className="commentList">
      <ul style={style.ul}>{comments}</ul>
      <div ref={ref}></div>
    </div>
  );
};

export default CommentList;
