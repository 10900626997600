import React from 'react';
import { IconButton, InputAdornment } from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import SnoozeIcon from '@material-ui/icons/Snooze';
import AlarmIcon from '@material-ui/icons/AddAlarm';

type Props = {
  selectedDate: Date;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
};

const DatePicker = (props: Props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker
        autoOk
        disablePast
        hideTabs
        ampm={false}
        value={props.selectedDate}
        onChange={props.setSelectedDate as any}
        allowKeyboardControl={false}
        leftArrowIcon={<AlarmIcon />}
        leftArrowButtonProps={{ 'aria-label': 'Prev month' }}
        rightArrowButtonProps={{ 'aria-label': 'Next month' }}
        rightArrowIcon={<SnoozeIcon />}
        format="yyyy/MM/dd HH:mm"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <AlarmIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
