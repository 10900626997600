import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import './styles/DisconnectionDialog.scss';

type Props = {
  handleExit(): void;
  handleCancel(): void;
  isMobile: boolean;
  isSmallDisplay: boolean;
};

const DisconnectionDialog = React.memo((props: Props) => {
  const style: { [key: string]: { [key: string]: React.CSSProperties } } = {
    common: {
      dialog: {
        borderRadius: '50%',
      },
      img: {
        position: 'relative',
        width: '100%',
      },
      div: {
        position: 'absolute',
        fontSize: '14px',
      },
      note: {
        display: 'flex',
      },
      buttonBox: {
        marginTop: '8px',
        width: '100%',
        textAlign: 'center',
      },
      cancel: {
        backgroundColor: '#ebebeb',
        borderRadius: '25px',
        width: props.isSmallDisplay ? '136px' : '148px',
      },
      exit: {
        marginLeft: '32px',
        backgroundColor: '#3c3c3c',
        color: '#fff',
        borderRadius: '25px',
        width: props.isSmallDisplay ? '136px' : '148px',
      },
      message: {
        marginTop: '0',
        padding: '0 32px',
        textAlign: 'left',
      },
      ul: {
        borderRadius: '4px',
        fontSize: '12px',
        margin: '0 8px',
        padding: '8px 8px 8px 32px',
        textAlign: 'left',
        backgroundColor: 'rgba(255,255,255, 0.8)',
      },
    },
    desktop: {
      div: {
        top: '144px',
        left: '144px',
      },
      confirm: {
        marginLeft: '40px',
      },
      note: {
        alignItems: 'center',
        marginLeft: '40px',
      },
      notice: {
        paddingRight: '24px',
        display: 'block',
        fontSize: '24px',
        lineHeight: 3,
        borderRight: '1px solid #666',
      },
    },
    mobile: {
      div: {
        textAlign: 'center',
        top: props.isSmallDisplay ? '64px' : '80px',
      },
      confirm: {
        marginBottom: '0',
      },
      note: {
        flexDirection: 'column',
      },
      notice: {
        padding: '0 48px 8px',
        borderBottom: '1px solid #666',
      },
    },
  };

  return (
    <Dialog
      className="disconnection"
      open={true}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description">
      <img
        style={style.common.img}
        src={`/images/${props.isMobile ? 'sp_out.png' : 'pc_out.png'}`}
        alt=""
      />
      <div
        style={
          props.isMobile
            ? { ...style.common.div, ...style.mobile.div }
            : { ...style.common.div, ...style.desktop.div }
        }>
        <p style={style.common.message}>
          京都橘大学のバーチャルキャンパスツアーにご参加いただき、ありがとうございました。皆さまにお会いできることを心より楽しみにしています。
        </p>
        <p
          style={props.isMobile ? style.mobile.confirm : style.desktop.confirm}>
          <FormattedMessage id="DisconnectionDialog.ConfirmMessage" />
        </p>

        <div style={style.common.buttonBox}>
          <Button
            style={style.common.cancel}
            variant="contained"
            onClick={props.handleCancel}>
            <FormattedMessage id="DisconnectionDialog.Cancel" />
          </Button>
          <Button
            style={style.common.exit}
            variant="contained"
            onClick={props.handleExit}>
            <FormattedMessage id="DisconnectionDialog.Exit" />
          </Button>
        </div>

        <div
          style={
            props.isMobile
              ? { ...style.common.note, ...style.mobile.note }
              : { ...style.common.note, ...style.desktop.note }
          }>
          <p>
            <span
              style={
                props.isMobile ? style.mobile.notice : style.desktop.notice
              }>
              <FormattedMessage id="DisconnectionDialog.Notice" />
            </span>
          </p>

          <ul style={style.common.ul}>
            <li>
              <FormattedMessage id="DisconnectionDialog.Demerit1" />
            </li>
            <li style={{ marginTop: '8px' }}>
              <FormattedMessage id="DisconnectionDialog.Demerit2-1" />
              {props.isMobile === false && <br />}
              <FormattedMessage id="DisconnectionDialog.Demerit2-2" />
            </li>
          </ul>
        </div>
      </div>
    </Dialog>
  );
});

export default DisconnectionDialog;
