import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  children?: React.ReactNode;
};

const VtourModal = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    modal: {
      margin: '16px auto',
      textAlign: 'center',
      boxSizing: 'border-box',
      borderRadius: '8px',
      minWidth: '320px',
      width: '100%',
      color: '#212121',
      padding: '16px 0 0',
      maxHeight: '100%',
      overflowY: 'scroll',
      overflowX: 'hidden',
    },
    title: {
      margin: 0,
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: 1,
      color: '#424242',
    },
    mapTitle: {
      margin: '0 auto 32px',
      fontWeight: 'normal',
      fontSize: '22px',
      lineHeight: 1.3,
      color: '#424242',
    },
    border: {
      // border: '2px solid #bdbdbd',
      borderRadius: '8px',
      marginBottom: '32px',
    },
  };
  return (
    <div style={style.modal}>
      <h2 style={style.title}>
        <img
          src="/images/stroly_lab.png"
          alt="Stroly LAB"
          width="88"
          style={{ verticalAlign: 'middle' }}
        />
        <FormattedMessage id="VtourModal.Vtour" />
      </h2>
      <h3 style={style.mapTitle}>
        <FormattedMessage id="VtourModal.GetStarted" />
      </h3>
      <div style={style.border}>{props.children}</div>
    </div>
  );
});

export default VtourModal;
