export const ja = {
  'VtourModal.Vtour': 'バーチャルツアー',
  'VtourModal.GetStarted': 'ツアーを設定する',
  'VtourDate.DateAndTime': '開始日時',
  'VtourForm.Participants': '参加人数（1-30）',
  'VtourForm.UpToJA': '人まで',
  'VtourForm.UpToEN': ' ',
  'VtourCheck.Options': 'オプション',
  'VtourCheck.AllowComment': '参加者のメッセージ送信を許可する',
  'VtourCheck.AllowEmoji': '参加者の絵文字利用を許可する',
  'VtourCheck.AllowDisplayCommentList':
    'メッセージ一覧に参加者のメッセージ表示を許可する',
  'VtourCheck.AllowGetLocation': '現在地の取得を許可する',
  'VtourUrl.Publish': 'ツアーのURLを発行する',
  'VtourUrl.UrlForUser': '参加者用URL',
  'VtourUrl.UrlForGuide': 'ガイド用URL',
  'VtourButton.Enter': 'ガイド用のツアー画面に入る',
};

export const en = {
  'VtourModal.Vtour': 'Virtual Tour',
  'VtourModal.GetStarted': 'Create a tour',
  'VtourDate.DateAndTime': 'Start Time & Date',
  'VtourForm.Participants': 'Number of participants (1-30)',
  'VtourForm.UpToJA': ' ',
  'VtourForm.UpToEN': 'Capacity',
  'VtourCheck.Options': 'Optional Settings',
  'VtourCheck.AllowComment': 'Allow participants to send messages',
  'VtourCheck.AllowEmoji': 'Allow participants to use Emojis',
  'VtourCheck.AllowDisplayCommentList': 'Display log of user messages',
  'VtourCheck.AllowGetLocation': 'Allow to use current location',
  'VtourUrl.Publish': 'Create an URL for the tour',
  'VtourUrl.UrlForUser': 'URL for participants',
  'VtourUrl.UrlForGuide': 'URL for Tour Guides',
  'VtourButton.Enter': 'Go to screen for tour guides',
};
