export const ja = {
  'InvalidUrlError.Message': 'このツアーは終了しました。',
  'Error.Message': 'エラーが発生しました。',
  'Error.Back': 'バーチャルマップに戻る',
  'Error.BackToStroly': 'Stroly.comに戻る',
};

export const en = {
  'InvalidUrlError.Message': 'This tour has now ended.',
  'Error.Message': 'An error has occurred.',
  'Error.Back': 'Go back to Virtual Map',
  'Error.BackToStroly': 'Go back to Stroly.com',
};
