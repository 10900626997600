import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { UserType } from '../../domains/user/user.model';
import * as Constants from '../../core/constants';

import ConnectionBeforeTour from './ConnectionBeforeTour';
import ConnectionConsent from './ConnectionConsent';

type Props = {
  isGuideMode: boolean;
  isMobile: boolean;
  isSmallDisplay: boolean;
  userType: UserType;
  title: string | undefined;
  isBeforeTour: boolean;
  startDate: moment.Moment | undefined;
  isFull: boolean;
  limit: number;
  children: any;
};

const ConnectionModal = React.memo((props: Props) => {
  const [variableStyle, setVariableStyle] = useState({
    logoImage: '',
    titleBox: {
      backgroundImage: '',
      width: '',
      height: '',
    },
    title: {
      color: '',
    },
  });

  useEffect(() => {
    const hour = moment().hour();
    const newVariableStyle = moment(hour).isBetween(5, 18)
      ? {
          ...variableStyle,
          logoImage: 'stroly_lab.png',
          title: {
            ...variableStyle.title,
            color: '#000',
          },
          titleBox: {
            ...variableStyle.titleBox,
            backgroundImage: props.isMobile ? 'sp_enter.png' : 'pc_enter.png',
            width: props.isMobile ? '1092' : '1400',
            height: props.isMobile ? '1400' : '726',
          },
        }
      : {
          ...variableStyle,
          logoImage: 'stroly_lab_night.png',
          title: {
            ...variableStyle.title,
            color: '#000',
          },
          titleBox: {
            ...variableStyle.titleBox,
            backgroundImage: props.isMobile ? 'sp_enter.png' : 'pc_enter.png',
            width: props.isMobile ? '1092' : '1400',
            height: props.isMobile ? '1400' : '726',
          },
        };

    setVariableStyle(newVariableStyle);
  }, []);

  const style: { [key: string]: { [key: string]: React.CSSProperties } } = {
    common: {
      modal: {
        backgroundColor: '#f4f4f4',
        margin: '0 auto',
        textAlign: 'center',
        boxSizing: 'border-box',
        borderRadius: '12px',
        width: '100%',
        color: '#000',
        overflowY: 'scroll',
      },
      titleBox: {
        position: 'relative',
      },
      background: {
        width: '100%',
        height: 'auto',
      },
      titleTextWrap: {
        position: 'absolute',
        top: '16px',
        width: '100%',
      },
      a: {
        color: variableStyle.title.color,
        textDecoration: 'none',
      },
      title: {
        color: variableStyle.title.color,
        margin: `0 auto 16px`,
        fontWeight: 'bold',
        lineHeight: 1,
      },
      mapTitle: {
        textAlign: 'left',
        fontWeight: 'normal',
        lineHeight: '24px',
        color: variableStyle.title.color,
      },
      full: {
        marginBottom: '0',
        padding: '28px 0 16px',
        background: '#f4f4f4',
      },
      limit: {
        fontSize: '14px',
        color: '#666',
      },
      border: {
        display: 'inline-block',
        textAlign: 'left',
        backgroundColor: '#f4f4f4',
        margin: props.isMobile ? '8px 16px' : '8px 24px',
      },
      guideBox: {
        marginRight: props.userType === UserType.Guide ? 'auto' : '',
        marginLeft: props.userType === UserType.Guide ? 'auto' : '',
        maxWidth: props.userType === UserType.Guide ? '408px' : '',
      },
      guideInfo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '8px',
      },
      buttonBox: {
        backgroundColor: '#f4f4f4',
      },
    },
    desktop: {
      modal: {
        maxWidth: '720px',
        maxHeight: '96vh',
      },
      title: {
        fontSize: '24px',
      },
      mapTitle: {
        fontSize: '14px',
        padding: '0 64px',
        marginTop: '64px',
        marginBottom: '40px',
      },
    },
    mobile: {
      modal: {
        maxWidth: '80vw',
        maxHeight: '88vh',
      },
      title: {
        fontSize: props.isSmallDisplay ? '20px' : '24px',
      },
      mapTitle: {
        fontSize: props.isSmallDisplay ? '12px' : '14px',
        marginTop: '64px',
        padding: '0 32px',
      },
    },
  };

  const [title, setTitle] = React.useState(Constants.Title);
  React.useEffect(() => {
    if (props.isGuideMode) {
      setTitle(Constants.TourTitle);
    }
  }, [props.isGuideMode]);

  const guideElements = props.children[0].props.children;
  const language = window.navigator.language;

  return (
    <div
      style={
        props.isMobile
          ? { ...style.common.modal, ...style.mobile.modal }
          : { ...style.common.modal, ...style.desktop.modal }
      }>
      {/* 同意書モーダル */}
      {props.isGuideMode && props.userType !== UserType.Guide ? (
        <ConnectionConsent />
      ) : null}

      <div style={style.common.titleBox}>
        {/* タイトル画像 */}
        <img
          src={`/images/${variableStyle.titleBox.backgroundImage}`}
          alt={`Stroly Lab ${title}`}
          style={style.common.background}
          width={variableStyle.titleBox.width}
          height={variableStyle.titleBox.height}
        />

        {/* タイトルテキスト */}
        <div style={style.common.titleTextWrap}>
          {!props.isBeforeTour ? (
            props.isFull ? (
              <h3
                style={
                  props.isMobile
                    ? { ...style.common.mapTitle, ...style.mobile.mapTitle }
                    : { ...style.common.mapTitle, ...style.desktop.mapTitle }
                }>
                <FormattedMessage
                  id="ConnectionModal.Full1"
                  values={{ title: props.title }}
                />
                <br />
                <FormattedMessage id="ConnectionModal.Full2" />
              </h3>
            ) : (
              <h3
                style={
                  props.isMobile
                    ? { ...style.common.mapTitle, ...style.mobile.mapTitle }
                    : { ...style.common.mapTitle, ...style.desktop.mapTitle }
                }>
                {props.isGuideMode ? (
                  <FormattedMessage
                    id="ConnectionModal.MapTitle2"
                    values={{
                      title: props.title,
                      text: (
                        <span style={{ color: '#e53935' }}>
                          事前申込いただいている方は、
                        </span>
                      ),
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="ConnectionModal.MapTitle1"
                    values={{ title: props.title }}
                  />
                )}
              </h3>
            )
          ) : null}
        </div>
      </div>

      {props.isBeforeTour && props.userType !== UserType.Guide ? (
        <ConnectionBeforeTour
          startDate={props.startDate}
          isMobile={props.isMobile}
          isSmallDisplay={props.isSmallDisplay}
        />
      ) : props.isFull && props.userType !== UserType.Guide ? (
        <p style={style.common.full}>
          <FormattedMessage id="ConnectionModal.Current" />
          {props.limit}
          <FormattedMessage id="ConnectionModal.PeopleJA" />
          {language !== 'ja' && <br />}
          <span style={style.common.limit}>
            <FormattedMessage
              id="ConnectionModal.Maximum"
              values={{ limit: props.limit }}
            />
          </span>
        </p>
      ) : (
        <div
          style={
            props.isMobile
              ? { ...style.common.border, ...style.mobile.border }
              : { ...style.common.border, ...style.desktop.border }
          }>
          {props.userType === UserType.Guide ? (
            <div style={style.common.guideBox}>
              <div style={style.common.guideInfo}>
                {guideElements[0]}
                {guideElements[1]}
              </div>
              {guideElements[2]}
              {guideElements[3]}
            </div>
          ) : (
            props.children[0]
          )}
        </div>
      )}

      {/* ボタン */}
      <div style={style.common.buttonBox}>
        {/* 「入場」ボタン */}
        {props.isFull && props.userType !== UserType.Guide
          ? null
          : props.children[1]}
        {/* 「閲覧モード」ボタン */}
        {props.isBeforeTour || props.userType === UserType.Guide
          ? null
          : props.children[2]}
      </div>

      {/* バーチャルマップの使い方 */}
      <div style={{ padding: '8px' }}>{props.children[3]}</div>
    </div>
  );
});

export default ConnectionModal;
