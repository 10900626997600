export class CentralMarkers {
  private readonly centrals = new Map<number, L.Marker>([]);

  has(token: number): boolean {
    return this.centrals.has(token);
  }

  get(token: number): L.Marker | undefined {
    if (this.centrals.has(token)) {
      return this.centrals.get(token);
    }
    return undefined;
  }

  set(token: number, centralMarker: L.Marker): void {
    if (!this.centrals.has(token)) {
      this.centrals.set(token, centralMarker);
    }
  }

  delete(token: number): void {
    if (this.centrals.has(token)) {
      this.centrals.delete(token);
    }
  }
}
