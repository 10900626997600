import React, { useState, useEffect, useRef, useCallback } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import moment from 'moment';

import { StateContext, DispatchContext } from '../core/contexts';
import { ConnectionsRepository } from '../repositories/connections.repository';
import {
  updateComments,
  updateIsCommentListOpen,
  updateUserList,
  updateIsDisabled,
  updateIsSharing,
} from '../core/reducer';

import { MapService, ActiveUser } from '../domains/map';
import { GuideOptions } from './AppContainer';
import { UserType } from '../domains/user/user.model';
import { CharacterType, Character } from '../domains/user/character';
import { CommentMessage, LocationMessage } from '../domains/ws/message.model';

import StrolyMap from '../components/map/StrolyMap';
import UserList from '../components/map/UserList';
import CommentContainer from './CommentContainer';
import CentralIcon from '../components/map/CentralIcon';
import AboutButton from '../components/map/AboutButton';
import ExitButton from '../components/map/ExitButton';
import LocateButton from '../components/map/LocateButton';
import Address from '../core/components/Address';
import Dialog from '../core/components/Dialog';
import About from '../components/map/About';
import Snackbar from '../core/components/Snackbar';
import DisconnectionDialog from '../components/map/DisconnectionDialog';

type Props = {
  intl: IntlShape;
  mapService: MapService;
  guideOptions: GuideOptions;
  mapID: string;
  name: string;
  characterType: CharacterType;
  character: Character;
  protocol: string;
  isLogedIn: boolean;
  isMobile: boolean;
  isSmallDisplay: boolean;
  isSnackbarOpen: boolean;
  exitUser: string;
  sendRemoveCentralMarker: (isLeaving: boolean) => void;
  setExitUser: React.Dispatch<React.SetStateAction<string>>;
  setIsSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sendToLocationWS: (data: LocationMessage) => void;
};

const MapContainer = (props: Props) => {
  const state = React.useContext(StateContext);
  const dispatch = React.useContext(DispatchContext);
  const connectionsRepository = useRef(new ConnectionsRepository());

  const isShareMode = useRef<boolean>(state.isSharing);
  useEffect(() => {
    isShareMode.current = state.isSharing;
  }, [state.isSharing]);

  const [isAboutDialogOpen, setIsAboutDialogOpen] = useState(false);
  function openAboutDialog(): void {
    gtag('event', 'click', {
      event_category: 'button',
      event_label: 'About',
    });
    setIsAboutDialogOpen(true);
  }

  function handleChatButtonClick() {
    dispatch(updateIsCommentListOpen(!state.isChatOpen));
  }

  function dispatchUpdateComments(comment: CommentMessage) {
    dispatch(updateComments(comment));
  }

  function setCommentToCentralMarker(message: CommentMessage) {
    props.mapService.setCommentToLocationMarker(message);
    props.mapService.setCommentToCentralMarker(message);
  }

  const setSelectedLandmarkMarker = useCallback(() => {
    props.mapService.setSelectedLandmarkMarker();

    gtag('event', 'click', {
      event_category: 'button',
      event_label: 'landmark close',
    });
  }, [props.mapService]);

  const [isUserListOpen, setIsUserListOpen] = useState(false);
  function handleCharacterInUserListClick(token: number) {
    props.mapService.panToCentralMarker(token);
    props.mapService.panToLocationMarker(token);

    gtag('event', 'click', {
      event_category: 'panTo',
      event_label: 'UserList',
    });
  }

  function controlLocate() {
    dispatch(updateIsDisabled(true));
    if (isShareMode.current) {
      props.mapService.panToLocationMarker(state.user.token);
      setTimeout(() => {
        props.mapService.stopGettingCurrentLocation();
        props.mapService.createCentralMarker(
          state.user.token,
          state.user.color,
          props.character,
        );
      }, 500);
      dispatch(updateIsSharing(!state.isSharing));
    } else {
      const message = props.intl.formatMessage({
        id: 'VmapContainer.LocateButton.ConfrimMessage',
      });
      const isOK = window.confirm(message);
      if (!isOK) {
        dispatch(updateIsDisabled(false));
        return;
      }
      props.mapService.getCurrentLocation();
    }
    gtag('event', 'click', {
      event_category: 'button',
      event_label: isShareMode.current ? 'Stop Sharing' : 'Share Your Location',
    });
  }

  const [readed, setReaded] = useState(0);
  const [unReaded, setUnReaded] = useState(0);
  useEffect(() => {
    const commentToken = state?.comments[state.comments.length - 1]?.token;
    if (!commentToken) {
      return;
    }
    if (state.isChatOpen) {
      setReaded(state.comments.length);
      setTimeout(() => {
        setUnReaded(0);
      }, 800);
      return;
    }
    if (commentToken === state.user.token) {
      setReaded(state.comments.length);
      return;
    }
    setUnReaded(state.comments.length - readed);
  }, [state.comments, state.isChatOpen, readed, state.user.token]);

  /**
   * 画面やタブを閉じる直前で実行したい処理
   */
  const [disconnectionDialogOpen, setDisconnectionDialogOpen] = useState(false);
  function beforeExit() {
    if (props.guideOptions?.isGuideMode) {
      setDisconnectionDialogOpen(true);
      return;
    }

    const confirmMessage = props.intl.formatMessage({
      id: 'VmapContainer.ExitButton.ConfirmMessage',
    });
    const isExit = window.confirm(confirmMessage);
    if (isExit) {
      exit();
    }
  }
  function handleExitCancel() {
    setDisconnectionDialogOpen(false);
  }
  function exit() {
    gtag('event', 'click', {
      event_category: 'button',
      event_label: 'Exit',
    });

    if (
      props.guideOptions.isGuideMode &&
      props.isLogedIn &&
      state.user.type === UserType.User
    ) {
      props.sendRemoveCentralMarker(true);
      connectionsRepository.current.delete(
        props.protocol,
        String(state.user.token),
      );

      window.onbeforeunload = null;
      window.onpagehide = null;
      window.onunload = null;
    }

    if (props.isLogedIn && state.user.type !== UserType.Browse) {
      props.sendRemoveCentralMarker(true);
    }

    window.location.reload();
  }

  function handleBeforeunload(event: BeforeUnloadEvent) {
    if (
      props.guideOptions.isGuideMode &&
      props.isLogedIn &&
      state.user.type === UserType.User
    ) {
      event.returnValue = '本当に終了してもいいですか？';

      props.sendRemoveCentralMarker(true);
      connectionsRepository.current.delete(
        props.protocol,
        String(state.user.token),
      );

      window.onpagehide = null;
      window.onunload = null;

      return;
    }

    if (props.isLogedIn && state.user.type !== UserType.Browse) {
      props.sendRemoveCentralMarker(true);
      window.onpagehide = null;
      window.onunload = null;
    }
  }

  function handlePagehideOrUnload(event: PageTransitionEvent | Event) {
    if (
      !props.guideOptions.isGuideMode &&
      props.isLogedIn &&
      state.user.type !== UserType.Browse
    ) {
      props.sendRemoveCentralMarker(true);
    }
  }

  const timer = useRef<NodeJS.Timeout>();
  const activeUsers = useRef(state.activeUsers);
  useEffect(() => {
    activeUsers.current = state.activeUsers;
  }, [state.activeUsers]);
  function checkUnusedUser() {
    if (!props.isLogedIn) {
      return;
    }

    if (timer.current) {
      clearInterval(timer.current);
    }
    timer.current = setInterval(() => {
      const now = moment();
      Object.values(activeUsers.current).forEach((activeUser: ActiveUser) => {
        if (
          state.user.token !== activeUser.token &&
          now.diff(activeUser.timestamp) > 1000 * 60 * 4
        ) {
          delete props.mapService.activeUsers[activeUser.token];
          dispatch(updateUserList(props.mapService.activeUsers));
          props.mapService.removeCentralMarker(activeUser.token);
          props.mapService.removeLocationMarker(activeUser.token);
          props.setExitUser(activeUser.name);
          props.setIsSnackbarOpen(true);

          if (props.guideOptions.isGuideMode) {
            connectionsRepository.current.delete(
              props.protocol,
              String(activeUser.token),
            );
          }
        }
      });
    }, 1000 * 60);
  }

  function handleVisibilityChange() {
    if (document.hidden === true) {
      if (timer.current) {
        clearInterval(timer.current);
      }
    }

    if (document.visibilityState === 'visible') {
      checkUnusedUser();
      if (
        !(
          props.isLogedIn &&
          props.guideOptions.isGuideMode &&
          state.user.type === UserType.User
        )
      ) {
        return;
      }
      // todo "value provided is not in a recognized RFC2822 or ISO format." という警告が出ているから直す
      const now = moment().utc();
      const lastTimestamp = moment(sessionStorage.getItem('timestamp'));
      // const lastTimestamp = activeUsers.current[state.user.token]?.timestamp;

      if (now.diff(lastTimestamp) > 1000 * 60 * 4) {
        alert('一定時間利用がなかったため、地図から退出します。');
        window.location.reload(true);
        return;
      }
      sessionStorage.setItem('timestamp', moment().utc().toString());
    }
  }

  useEffect(() => {
    checkUnusedUser();
    sessionStorage.removeItem('timestamp');
    window.document.onvisibilitychange = () => handleVisibilityChange();
    window.onbeforeunload = (event: BeforeUnloadEvent) =>
      handleBeforeunload(event);
    window.onpagehide = (event: PageTransitionEvent) =>
      handlePagehideOrUnload(event);
    window.onunload = (event: Event) => handlePagehideOrUnload(event);

    return () => {
      sessionStorage.removeItem('timestamp');
      window.document.onvisibilitychange = null;
      window.onbeforeunload = null;
      window.onpagehide = null;
      window.onunload = null;
    };
  }, [props.character, props.isLogedIn, state.user.type]);

  return (
    <StrolyMap
      isLogedIn={props.isLogedIn}
      user={state.user}
      mapService={props.mapService}
      protocol={props.protocol}
      character={props.character}
      mapID={props.mapID}
      dispatch={dispatch}
      sendDatatoWS={props.sendToLocationWS}
      sendRemoveCentralMarker={props.sendRemoveCentralMarker}>
      <>
        {state.user.type === UserType.Browse ? <CentralIcon /> : null}
        <CommentContainer
          name={props.name}
          characterType={props.characterType}
          guideOptions={props.guideOptions}
          isLogedIn={props.isLogedIn}
          isShareMode={isShareMode.current}
          isMobile={props.isMobile}
          unReaded={unReaded}
          protocol={props.protocol}
          mapService={props.mapService}
          setCommentToCentralMarker={setCommentToCentralMarker}
          setSelectedLandmarkMarker={setSelectedLandmarkMarker}
          dispatchUpdateComments={dispatchUpdateComments}
          handleChatButtonClick={handleChatButtonClick}
        />
        <UserList
          user={state.user}
          isLogedIn={props.isLogedIn}
          activeUsers={state.activeUsers}
          isUserListOpen={isUserListOpen}
          setIsUserListOpen={setIsUserListOpen}
          panTo={handleCharacterInUserListClick}
        />
        {state.user.type === UserType.Guide ||
        (state.user.type !== UserType.Browse &&
          props.guideOptions.isLocateEnabled) ? (
          <LocateButton
            isDisabled={state.isDisabled}
            isSharing={state.isSharing}
            controlLocate={controlLocate}
          />
        ) : null}
        <AboutButton openAboutDialog={openAboutDialog} />
        <Dialog
          isDialogOpen={isAboutDialogOpen}
          setIsDialogOpen={setIsAboutDialogOpen}>
          <About guideOptions={props.guideOptions} />
        </Dialog>
        <ExitButton handleExitButtonClick={beforeExit} />
        <Address
          color="#fff"
          style={
            {
              textShadow: '0 0 2px #424242',
              position: 'absolute',
              bottom: '4px',
              left: 'calc(50% - 71px)',
              color: '#fff',
            } as {
              [key: string]: React.CSSProperties;
            }
          }
        />
        <Snackbar
          isSnackbarOpen={props.isSnackbarOpen}
          setIsSnackbarOpen={props.setIsSnackbarOpen}>
          <span>{props.exitUser} left the map.</span>
        </Snackbar>
      </>
      {disconnectionDialogOpen && (
        <DisconnectionDialog
          isMobile={props.isMobile}
          isSmallDisplay={props.isSmallDisplay}
          handleExit={exit}
          handleCancel={handleExitCancel}
        />
      )}
    </StrolyMap>
  );
};

export default injectIntl(MapContainer);
