import {
  LandmarkContent,
  LandmarkContentLocalized,
  MetaData,
  MetaDataLocalized,
} from './../domains/map/map.model';
import { ActiveUsers } from '../domains/map';
import { User, UserType } from '../domains/user/user.model';
import { Token } from '../domains/user/token';
import { Color } from '../domains/user/color';
import { CommentMessage } from '../domains/ws/message.model';

/**
 * State
 */
export interface State {
  /** ユーザー情報 */
  user: User;
  activeUsers: ActiveUsers;
  /** コメント一覧 */
  comments: CommentMessage[];
  /** ローディング中かどうか */
  isLoading: boolean;
  isSharing: boolean;
  isDisabled: boolean;
  /** ランドマークペインが開いているかどうか */
  isLandmarkOpen: boolean;
  /** コメントリストペインが開いているかどうか */
  isChatOpen: boolean;
  metaData: MetaDataLocalized;
  landmarkCategories: { [key: string]: string };
  landmarkContent: LandmarkContentLocalized;
}

export const initialState: State = {
  user: {
    type: UserType.User,
    token: new Token().getToken(),
    color: new Color().getColor(),
  },
  activeUsers: {},
  comments: [],
  isLoading: false,
  isSharing: false,
  isDisabled: true,
  isLandmarkOpen: false,
  isChatOpen: false,
  metaData: null as any,
  landmarkCategories: {},
  landmarkContent: '' as any,
};

/**
 * Action
 */
export enum actionType {
  UPDATE_USER,
  UPDATE_USER_LIST,
  UPDATE_COMMENTS,
  DELETE_COMMENTS,
  UPDATE_IS_LOADING,
  UPDATE_IS_SHARING,
  UPDATE_IS_DISABLED,
  UPDATE_IS_LANDMARK_OPEN,
  UPDATE_IS_CHAT_OPEN,
  SET_META_DATA,
  SET_LANDMARK_CATEGORIES,
  SET_LANDMARK_CONTENT,
}

export function updateUser(payload: Partial<User>) {
  return {
    type: actionType.UPDATE_USER,
    payload,
  };
}

export function updateUserList(payload: ActiveUsers) {
  return {
    type: actionType.UPDATE_USER_LIST,
    payload,
  };
}

export function updateComments(payload: CommentMessage) {
  return {
    type: actionType.UPDATE_COMMENTS,
    payload,
  };
}

export function deleteComments(payload: number) {
  return {
    type: actionType.DELETE_COMMENTS,
    payload,
  };
}

export function updateIsLoading(payload: boolean) {
  return {
    type: actionType.UPDATE_IS_LOADING,
    payload,
  };
}

export function updateIsDisabled(payload: boolean) {
  return {
    type: actionType.UPDATE_IS_DISABLED,
    payload,
  };
}

export function updateIsLandmarkOpen(payload: boolean) {
  return {
    type: actionType.UPDATE_IS_LANDMARK_OPEN,
    payload,
  };
}

export function updateIsCommentListOpen(payload: boolean) {
  return {
    type: actionType.UPDATE_IS_CHAT_OPEN,
    payload,
  };
}

export function updateIsSharing(payload: boolean) {
  return {
    type: actionType.UPDATE_IS_SHARING,
    payload,
  };
}

export function setMetaData(payload: MetaData) {
  return {
    type: actionType.SET_META_DATA,
    payload: {
      ...payload,
      title: getContentByLang(payload.title),
      description: getContentByLang(payload.description),
    },
  };
}

export function setLandmarkCategories(
  payload: Map<string, { [key: string]: string }>,
) {
  const categories: { [key: string]: string | undefined } = {};
  Array.from(payload).forEach((category) => {
    const [key, value] = category;
    categories[key] = getContentByLang(value);
  });

  return {
    type: actionType.SET_LANDMARK_CATEGORIES,
    payload: categories,
  };
}

export function setLandmarkContnet(payload: LandmarkContent) {
  return {
    type: actionType.SET_LANDMARK_CONTENT,
    payload: {
      ...payload,
      name: getContentByLang(payload.name),
      description: getContentByLang(payload.description),
    },
  };
}

function getContentByLang(content: any): string | undefined {
  const browserLanguage =
    (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language;
  let lang: string = browserLanguage.split('-')[0];

  if (browserLanguage === 'zh-CN') {
    lang = 'zh-Hans';
  }

  if (browserLanguage === 'zh-TW') {
    lang = 'zh-Hant';
  }

  if (!content) {
    return undefined;
  }

  if (lang in content && content[lang] !== '') {
    return content[lang];
  }

  if ('en' in content && content.en !== '') {
    return content.en;
  }

  return Object.values(content).find((value) => {
    return value !== '';
  }) as string | undefined;
}

/**
 * Reducer
 */
export function reducer(state: State, action: any): State {
  switch (action.type) {
    case actionType.UPDATE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case actionType.UPDATE_USER_LIST:
      return {
        ...state,
        activeUsers: action.payload,
      };
    case actionType.UPDATE_COMMENTS:
      return {
        ...state,
        comments: state.comments.concat([action.payload]),
      };
    case actionType.DELETE_COMMENTS:
      const filteredComments = state.comments.filter(
        (comment: CommentMessage) => {
          if (comment.token === action.payload) {
            return false;
          }
          return true;
        },
      );
      return {
        ...state,
        comments: filteredComments,
      };
    case actionType.UPDATE_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actionType.UPDATE_IS_SHARING:
      return {
        ...state,
        isSharing: action.payload,
      };
    case actionType.UPDATE_IS_DISABLED:
      return {
        ...state,
        isDisabled: action.payload,
      };
    case actionType.UPDATE_IS_LANDMARK_OPEN:
      return {
        ...state,
        isLandmarkOpen: action.payload,
      };
    case actionType.UPDATE_IS_CHAT_OPEN:
      return {
        ...state,
        isChatOpen: action.payload,
      };
    case actionType.SET_META_DATA:
      return {
        ...state,
        metaData: action.payload,
      };
    case actionType.SET_LANDMARK_CATEGORIES:
      return {
        ...state,
        landmarkCategories: action.payload,
      };
    case actionType.SET_LANDMARK_CONTENT:
      return {
        ...state,
        landmarkContent: action.payload,
      };
    default:
      return state;
  }
}
