import * as L from 'leaflet';

import { CentralUserMarker } from './central-user-marker';
import { CentralGuideMarker } from './central-guide-marker';

import { CharacterType } from './../user/character';

export type CentralMarker = {
  readonly marker: L.Marker;
};

export class CentralMarkerFactory {
  private readonly types: Map<CharacterType, CentralMarker | any> = new Map();

  constructor() {
    this.types.set(CharacterType.Boy, CentralUserMarker);
    this.types.set(CharacterType.Girl, CentralUserMarker);
    this.types.set(CharacterType.Baby, CentralUserMarker);
    this.types.set(CharacterType.Balloon, CentralUserMarker);
    this.types.set(CharacterType.Bear, CentralUserMarker);
    this.types.set(CharacterType.Dog, CentralUserMarker);
    this.types.set(CharacterType.Blackcat, CentralUserMarker);
    this.types.set(CharacterType.Tigercat, CentralUserMarker);
    this.types.set(CharacterType.Whitecat, CentralUserMarker);
    this.types.set(CharacterType.Guide, CentralGuideMarker);
    this.types.set(CharacterType.StudentMan, CentralUserMarker);
    this.types.set(CharacterType.StudentMan2, CentralUserMarker);
    this.types.set(CharacterType.StudentWoman, CentralUserMarker);
    this.types.set(CharacterType.Navi, CentralUserMarker);
    this.types.set(CharacterType.Maiko, CentralUserMarker);
    this.types.set(CharacterType.DogVtour, CentralUserMarker);
    this.types.set(CharacterType.Cat, CentralUserMarker);
    this.types.set(CharacterType.Alien, CentralUserMarker);
    this.types.set(CharacterType.Monster, CentralUserMarker);
    this.types.set(CharacterType.Robot, CentralUserMarker);
  }

  centralMarkerByName(type: CharacterType) {
    return this.types.get(type);
  }
}
