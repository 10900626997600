import React from 'react';

import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import { UserType } from '../../domains/user/user.model';
import { EmojiName } from '../../domains/ws/emoji';
import { GuideOptions } from '../../containers/AppContainer';

import CommentEmoji from './CommentEmoji';
import CommentSendMessage from './CommentSendMessage';
import CommentButton from './CommentButton';

type Props = {
  userType: UserType;
  guideOptions: GuideOptions;
  comment: string;
  unReaded: number;
  isDisabled: boolean;
  isChatOpen: boolean;
  handleSendMessage(): void;
  handleSendEmoji(emoji: EmojiName): void;
  handleChange(value: string): void;
  handleChatButtonClick(): void;
  children: React.ReactChild;
};

const CommentForm = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    wrapper: {
      width: '240px',
      position: 'absolute',
      top: '8px',
      right: '8px',
      textAlign: 'center',
      zIndex: 1000,
      backgroundColor: '#f4f4f4',
      borderRadius: '4px',
      filter: 'drop-shadow(0px 0 2px #333)',
    },
    div: {
      display: 'inline-block',
    },
    paper: {
      padding: '0 8px',
      alignItems: 'center',
      margin: '0 auto',
      height: '100%',
      width: '100%',
      boxSizing: 'border-box',
    },
    divider: {
      height: 24,
      margin: '8px',
    },
    emojis: {
      display: 'flex',
      justifyContent: 'space-around',
    },
  };

  return (
    <div style={style.wrapper}>
      {props.userType === UserType.Guide ||
      (props.userType === UserType.User &&
        props.guideOptions?.isCommentEnabled) ||
      (props.userType === UserType.User &&
        props.guideOptions?.isEmojiEnabled) ? (
        <div style={style.div}>
          {props.userType === UserType.Guide ||
          (props.userType === UserType.User &&
            props.guideOptions?.isCommentEnabled) ? (
            <Paper style={style.paper}>
              <CommentSendMessage
                comment={props.comment}
                isDisabled={props.isDisabled}
                handleChange={props.handleChange}
                handleSendMessage={props.handleSendMessage}
              />
            </Paper>
          ) : null}

          {props.userType === UserType.Guide ||
          (props.userType === UserType.User &&
            props.guideOptions?.isEmojiEnabled) ? (
            <div style={style.emojis}>
              <CommentEmoji
                emoji={EmojiName.like}
                label={'like'}
                handleSendEmoji={props.handleSendEmoji}
              />
              <Divider style={style.divider} orientation="vertical" />
              <CommentEmoji
                emoji={EmojiName.love}
                label="love"
                handleSendEmoji={props.handleSendEmoji}
              />
              <Divider style={style.divider} orientation="vertical" />
              <CommentEmoji
                emoji={EmojiName.look}
                label="look"
                handleSendEmoji={props.handleSendEmoji}
              />
            </div>
          ) : null}
        </div>
      ) : null}

      <CommentButton
        unReaded={props.unReaded}
        isChatOpen={props.isChatOpen}
        handleChatButtonClick={props.handleChatButtonClick}>
        {props.children}
      </CommentButton>
    </div>
  );
});

export default CommentForm;
