import React from 'react';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';

type Props = {
  isDisabled: boolean;
  isGuideMode: boolean;
  handleClick: () => void;
};

const ConnectionButton = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    button: {
      width: '240px',
      lineHeight: '32px',
      borderRadius: '25px',
      fontSize: '16px',
      fontWeight: 'bold',
    },
    enabled: {
      backgroundColor: '#eab540',
      color: '#000',
    },
    disabled: {
      cursor: 'not-allowed',
    },
  };

  const handleClick = React.useCallback(() => {
    props.handleClick();
  }, [props]);

  const i18nId = props.isGuideMode
    ? 'ConnectionButton.Guide.Start'
    : 'ConnectionButton.User.Start';

  return (
    <Button
      style={
        props.isDisabled
          ? { ...style.button, ...style.disabled }
          : { ...style.button, ...style.enabled }
      }
      variant="contained"
      onClick={handleClick}
      disabled={props.isDisabled}>
      <FormattedMessage id={i18nId} />
    </Button>
  );
});

export default ConnectionButton;
