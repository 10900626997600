import React from 'react';
import GpsFixed from '@material-ui/icons/GpsFixed';

import { GuideOptions } from '../../containers/AppContainer';

type Props = {
  guideOptions: GuideOptions;
};

const About = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    about: {
      padding: '16px',
    },
    title: {
      borderRadius: '4px',
      marginTop: 0,
      fontSize: '16px',
      backgroundColor: '#666',
      color: '#fff',
      padding: '8px',
      textAlign: 'center',
    },
    description: {
      fontSize: '14px',
      textAlign: 'justify',
    },
    ul: {
      listStyleType: 'none',
      padding: '0',
    },
    li: {
      display: 'flex',
      textAlign: 'left',
      padding: '16px 0',
      borderBottom: '1px dashed #666',
      fontSize: '14px',
    },
    text: {
      margin: 0,
    },
    image: {
      paddingRight: '16px',
      boxSizing: 'border-box',
      width: '64px',
    },
    button: {
      textAlign: 'center',
      boxSizing: 'border-box',
      borderRadius: '50%',
      backgroundColor: '#fff',
    },
    aboutButton: {
      width: '48px',
      height: '48px',
      lineHeight: '48px',
      filter: 'drop-shadow(0px 0 2px #333)',
      paddingTop: '4px',
    },
    userListButton: {
      paddingTop: '8px',
      width: '48px',
      height: '48px',
      filter: 'drop-shadow(0px 0 2px #333)',
    },
    gpsButton: {
      margin: '4px 0 0 4px',
      width: '40px',
      height: '40px',
      lineHeight: '40px',
      paddingTop: '6px',
      boxShadow: '0 0 0 4px rgba(255,255,255,0.5)',
      filter: 'drop-shadow(0px 0 4px #333)',
    },
    exitButton: {
      marginLeft: '8px',
      textAlign: 'center',
      boxSizing: 'border-box',
      paddingTop: '8px',
      backgroundColor: '#333',
      borderRadius: '50%',
      width: '32px',
      height: '32px',
    },
  };

  return (
    <div style={style.about}>
      <h2 style={style.title}>Stroly Virtual Mapとは？</h2>
      <p style={style.description}>
        Stroly Virtual
        Mapでは、Stroly.comで作成されたイラスト地図の中を、お好きなキャラクターをアバターにして散策することができます。さらに、チャット機能がありますので、同時に参加している複数のユーザーとの地図上でのコミュニケーションもお楽しみいただけます。
      </p>
      <div>
        <h3 style={style.title}>各ボタンの機能</h3>
        <ul style={style.ul}>
          <li style={style.li}>
            <div style={style.image}>
              <div style={{ ...style.button, ...style.aboutButton }}>
                <img src="/images/icons/about.svg" width="16" alt="about" />
              </div>
            </div>
            <p style={style.text}>
              <b>使い方</b>
              <br />
              使い方の説明（この画面）を開きます。
            </p>
          </li>
          <li style={style.li}>
            <div style={style.image}>
              <div style={{ ...style.button, ...style.userListButton }}>
                <img src="/images/icons/user.svg" alt="user" width="24" />
                <br />
                <img
                  src="/images/icons/arrow_bottom_black.svg"
                  alt="open"
                  width="12"
                  style={{ verticalAlign: '3px' }}
                />
              </div>
            </div>
            <p style={style.text}>
              <b>参加者一覧</b>
              <br />
              地図上に存在する参加者の一覧を表示します。表示されている参加者をクリックすると、その参加者のところまでアバターを移動させることができます。
            </p>
          </li>
          {props.guideOptions.isLocateEnabled ? (
            <li style={style.li}>
              <div style={style.image}>
                <div style={{ ...style.button, ...style.gpsButton }}>
                  <GpsFixed />
                </div>
              </div>
              <p style={style.text}>
                <b>現在地「GPS」</b>
                <br />
                「現在地」モードに切り替わります。現在地を取得して、他のユーザーにあなたの位置情報を地図上で共有する事ができます。
              </p>
            </li>
          ) : null}
          <li style={style.li}>
            <div style={style.image}>
              <div style={style.exitButton}>
                <img src="/images/icons/exit.svg" alt="send" width="16" />
              </div>
            </div>
            <p style={style.text}>
              <b>終了</b>
              <br />
              地図から退出します。
            </p>
          </li>
          {props.guideOptions.isCommentEnabled ||
          props.guideOptions.isEmojiEnabled ? (
            <li style={style.li}>
              <p style={style.text}>
                <b>コメント機能とは？</b>
                <br />
                地図に参加しているアバター同士で、絵文字やコメントによるチャット機能による気軽なコミュニケーションが可能です。
              </p>
            </li>
          ) : null}
        </ul>
      </div>
    </div>
  );
});

export default About;
