import { useState, ChangeEvent } from 'react';

export function useCheckbox(initialValue: number) {
  const [value, setValue] = useState(initialValue);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const checkedValue = event.currentTarget.value === '0' ? 1 : 0;
    setValue(checkedValue);
  }

  return {
    value,
    handleChange,
  };
}
