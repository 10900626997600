import { useRef, useState } from 'react';
import moment from 'moment';
import { History } from 'history';

import { ConnectionsRepository } from '../../repositories/connections.repository';
import { EventsRepository } from '../../repositories/events.repository';
import { updateIsLoading } from '../../core/reducer';
import { useStartDatetime } from './useStartDatetime';

export const useAvailabilityService = (
  history: History,
  dispatch: React.Dispatch<any>,
  protocol: string,
  limit: number,
) => {
  const connectionsRepository = useRef(new ConnectionsRepository());
  const eventsRepository = useRef(new EventsRepository());

  const [isFull, setIsFull] = useState<boolean>(false);
  const [isBeforeTour, setIsBeforeTour] = useState<boolean>(false);
  const [participants, setParticipants] = useState(NaN);

  const startDatetime = useStartDatetime();

  /**
   * Public Methods
   */
  async function checkStartDateAndParticipants() {
    dispatch(updateIsLoading(true));
    const connections = connectionsRepository.current.get(protocol);
    const events = eventsRepository.current.get(protocol);

    try {
      const [connectionsRes, eventsRes] = await Promise.all([
        connections,
        events,
      ]);
      dispatch(updateIsLoading(false));

      const datetime = moment(eventsRes.map_events[0].start_datetime);
      startDatetime.setStartDatetime(datetime);

      if (startDatetime.getIsBeforeTour()) {
        setIsBeforeTour(true);
        return true;
      }

      if (startDatetime.getIsAfterTour()) {
        history.push(`/invalid/error`);
        return true;
      }

      if (connectionsRes.count >= limit) {
        setIsFull(true);
        setParticipants(limit);
        return true;
      }

      setParticipants(connectionsRes.count);
    } catch {
      dispatch(updateIsLoading(false));
      history.push(`/error/${protocol}`, history.location.pathname);
    }
  }

  async function checkInBrowseMode() {
    dispatch(updateIsLoading(true));

    try {
      const eventsRes = await eventsRepository.current.get(protocol);
      dispatch(updateIsLoading(false));

      const datetime = moment(eventsRes.map_events[0].start_datetime);
      startDatetime.setStartDatetime(datetime);

      if (startDatetime.getIsAfterTour()) {
        history.push(`/invalid/error`);
        return;
      }
    } catch {
      dispatch(updateIsLoading(false));
      history.push(`/error/${protocol}`, history.location.pathname);
    }
  }

  let timer!: NodeJS.Timeout;
  function checkIsAfterTour(message: string) {
    if (startDatetime.getIsAfterTour()) {
      return;
    }
    timer = setInterval(() => {
      if (startDatetime.getIsAfterTour()) {
        clearInterval(timer);
        alert(message);
        history.push(`/invalid/error`);
      }
    }, 1000 * 60);
  }

  return {
    isFull,
    isBeforeTour,
    participants,
    startDate: startDatetime.startDatetime,
    checkIsAfterTour,
    checkStartDateAndParticipants,
    checkInBrowseMode,
  };
};
