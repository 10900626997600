export class Token {
  private readonly token: number;

  constructor() {
    this.token = this.createToken();
  }

  getToken() {
    return this.token;
  }

  private createToken() {
    return new Date().getTime() * Math.random();
  }
}
