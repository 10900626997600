import React from 'react';
import MuiBackdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

type Props = {
  isBackdropOpen: boolean;
};

const Backdrop = (props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    div: {
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: 10000,
    },
  };

  return (
    <MuiBackdrop style={style.div} open={props.isBackdropOpen} invisible={true}>
      <CircularProgress color="inherit" />
    </MuiBackdrop>
  );
};

export default Backdrop;
